import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRankings } from "../redux/reducers/rankings";
import { useNavigate } from "react-router-dom";
import { TrendingTableSkeleton } from "../common/TrendingTableSkeleton";
import ImageChecker from "../sharedComponents/Image";
import DefaultCover from "../utils/svgs/defaulCover.png";
import { convertNumberToHumanReadable } from "../utils/numbersConversion";
import venomIcon from "../utils/svgs/v.svg";
import { Link, NavLink } from "react-router-dom";
import VerifiedIcon from "../utils/svgs/verifiedIcon.png";
function Stats({categoryName}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(1);
  const [filters,setFilters]=useState("")

  const handleNavLinkClick = (idx, detail) => {
    setActiveIndex(idx);
    setFilters(detail.name)
  };

  const { rankings, loader, success } = useSelector((state) => state.rankings);

  useEffect(() => {
    dispatch(getAllRankings({interval:filters}));
  }, [filters]);
  

  const details = [{name:"7D",value:""}, {name:"1M",value:""}, {name:"ALL",value:""}];

  return (
    <>
      <div className="mt-16  px-4 py-4 md:px-8 md:py-8 relative">
        <div className="flex items-center justify-between">
          <div className="px-7 text-[1.5em] font-extrabold md:text-[2em]">
            Stats
          </div>
          <div>
            {details?.map((detail, idx) => (
              <NavLink
                key={idx}
                className={`py-2 px-4 text-[0.8em] font-semibold rounded md:text-[1em] ${
                  idx === activeIndex ? " bg-slate-300" : "bg-detailColor1"
                } `}
                onClick={() => handleNavLinkClick(idx, detail)}
              >
                {detail.name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="border-t border-gray-200 w-full mt-4"></div>
        <div className="overflow-x-auto ">
          {loader ? (
            <div className="space-y-4">
              {Array(6)
                .fill(true)
                .map((item, index) => (
                  <TrendingTableSkeleton key={index} />
                ))}
            </div>
          ) : (
            <table className="min-w-full border-transparent overflow-x-scroll">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 bg-[#fff] text-left text-sm font-medium text-gray-500 uppercase border-transparent"
                  >
                    No.
                  </th>
                  <th
                    scope="col"
                    className="px-8 bg-[#fff] text-left text-sm font-medium text-gray-500 uppercase border-transparent"
                  >
                    Collection
                  </th>
                  <th
                    scope="col"
                    className="px-6 bg-[#fff] text-left text-sm font-medium text-gray-500 uppercase border-transparent"
                  >
                    Volume
                  </th>
                  <th
                    scope="col"
                    className="px-6 bg-[#fff] text-left text-sm font-medium text-gray-500 uppercase border-transparent"
                  >
                    Floor
                  </th>
                  <th
                    scope="col"
                    className="px-6 bg-[#fff] text-left text-sm font-medium text-gray-500 uppercase border-transparent"
                  >
                    Last Sale
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loader &&
                  rankings?.length > 0 &&
                  rankings?.map((el, index) => (
                    <tr
                      key={index}
                      onClick={() =>
                        navigate(`collections/${el.collectionAddress}`)
                      }
                    >
                      <td className="px-6">
                        <div className="text-xl font-medium text-Black flex items-center gap-3">
                          {index + 1}
                        </div>
                      </td>
                      <td className="px-8">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-20 w-20 ">
                            <ImageChecker
                              imageUrl={el.collectionImage}
                              altImage={DefaultCover}
                              styles={"h-20 w-20 rounded-md"}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-xl font-medium text-gray-900 flex items-center gap-2">
                              {el.collectionName}
                              {el.isverified ? (
                                <img
                                  src={VerifiedIcon}
                                  className="w-[19px] h-[19px]"
                                  alt="Verified Icon"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="text-sm text-gray-500 flex gap-2 items-center">
                              {el.floorPrice
                                ? convertNumberToHumanReadable(el.floorPrice)
                                : "0.00 "}
                              <img
                                src={venomIcon}
                                alt="V"
                                color="black"
                                width={13}
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6">
                        <div className="text-xl font-medium text-Black flex items-center gap-3">
                          {el.totalVolume
                            ? convertNumberToHumanReadable(el.totalVolume)
                            : "0.00 "}
                          <img
                            src={venomIcon}
                            alt="V"
                            color="black"
                            width={18}
                          />
                        </div>
                      </td>
                      <td className="px-6">
                        <div className="text-xl font-medium text-Black flex items-center gap-3">
                          {el.floorPrice
                            ? convertNumberToHumanReadable(el.floorPrice)
                            : "0.00 "}
                          <img
                            src={venomIcon}
                            alt="V"
                            color="black"
                            width={18}
                          />
                        </div>
                      </td>
                      <td className="px-6">
                        <div className="text-xl font-medium text-Black flex items-center gap-3">
                          {el.lastSale
                            ? convertNumberToHumanReadable(el.lastSale)
                            : "0.00 "}
                          <img
                            src={venomIcon}
                            alt="V"
                            color="black"
                            width={18}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default Stats;
