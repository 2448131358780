import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/service";

// Create async thunks
export const getOwnerAndManager = createAsyncThunk(
  "getOwnerAndManager",
  async ({ collectionAddress, NftAddress }, { rejectWithValue }) => {
    try {
      const res = await API.get(
        `/collections/${collectionAddress}/nfts/${NftAddress}/sync`
      );
      if (res.data.success) {
        return res.data.nftDetails;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err); // Return actual error object
    }
  }
);

const initialState = {
  nft_Owner_Manager: {},
  isOwnerFetchLoading: false,
  isOwnerFetchSuccess: false,
  isFetchOwnerError: null,
};

export const nftOwnerSlice = createSlice({
  name: "nftOwner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOwnerAndManager.pending, (state) => {
        state.isOwnerFetchLoading = true;
        state.isOwnerFetchSuccess = false;
        state.isFetchOwnerError = null; // Reset isFetchOwnerError state
      })
      .addCase(getOwnerAndManager.fulfilled, (state, { payload }) => {
        state.nft_Owner_Manager = payload;
        state.isOwnerFetchLoading = false;
        state.isOwnerFetchSuccess = true;
      })
      .addCase(getOwnerAndManager.rejected, (state, { payload }) => {
        state.isOwnerFetchLoading = false;
        state.isFetchOwnerError = payload; // Set isFetchOwnerError state with actual error object
      });
  },
});

export default nftOwnerSlice.reducer;
