import React, { useState } from "react";
import Icon from "../../utils/svgs/search.png";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const SearchBar = ({ onSearch, handleReset, searchText, scrolling }) => {
  console.log(scrolling, "hello");
  return (
    <div
      className={`${
        scrolling ? "border border-1 border-[#A2B7D8] rounded-lg" : ""
      } relative w-[45vw] lg:w-[45vw] md:w-[70vw] sm:w-[70vw] h-[42px]`}
    >
      <input
        type="text"
        className={` pr-8 ${
          scrolling ? "bg-[white]" : "bg-[#1F3E50] "
        } w-full h-full pl-8 pr-2 rounded-lg ${
          scrolling ? "text-black" : "text-white"
        }`}
        placeholder="Search items, collections and accounts...."
        value={searchText}
        onChange={(e) => onSearch(e.target.value)}
      />
      <div className="absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchOutlined style={{ color: "#A2B7D8" }} />
      </div>

     {searchText?.length>0 && <CloseOutlined
        style={{ color: "#A2B7D8" }}
        className="cursor-pointer absolute inset-y-0 right-2 flex items-center"
        onClick={handleReset}
      />}
    </div>
  );
};

export default SearchBar;
