import React from 'react'
import { useNavigate } from "react-router";
import SvgIcons from "../../utils/svgs/SvgIcons";
import { convertNumberToHumanReadable } from "../../utils/numbersConversion";
import ImageChecker from "../../sharedComponents/Image";
import DefaultCover from "../../utils/svgs/defaulCover.png";

function DropListCard({drop}) {
  const navigate = useNavigate();
  return (
    <div className=" cursor-pointer m-2 overflow-hidden	cursor: pointer h-[367px] w-[336px] border border-1 rounded-[13px] drop-shadow transition-transform transform-gpu hover:translate-y-[-5px]">
      <div
        className="w-full h-[192]"
        onClick={() => navigate(`/drops/${drop.dropId}`)}
      >
        <div className=" w-full h-[222px] transition-transform overflow-hidden transform hover:scale-105 object-contain">
          <ImageChecker
            imageUrl={drop?.leftImage}
            altImage={DefaultCover}
            styles={"h-[100%] w-[100%]"}
            divStyle={"h-[100%] w-[100%]"}
          />
        </div>
      </div>
      <div className="flex flex-col justify-between w-[253px] h-[88px] m-[1em] ">
        <div className="flex gap-1 items-center">
          <span className="font-bold text-[16px] ml-2 mr-4">
            {drop.dropName}
          </span>
          <SvgIcons.BlueChecked />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <span className="text-[#707A83] text-[12.74px] font-bold">
              Floor
            </span>
            <span className="font-extrabold">
              {drop.floorPrice
                ? convertNumberToHumanReadable(drop.floorPrice)
                : "0.00 "}
              V
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-[#707A83] text-[12.74px] font-bold">
              Total Volume
            </span>
            <span className="font-extrabold">
              {drop.totalVolume
                ? convertNumberToHumanReadable(drop.totalVolume)
                : "0.00 "}
              V
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropListCard