import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import { useSelector } from "react-redux";
import BannerCard from "../common/bannerCard";

import "react-multi-carousel/lib/styles.css";
import SkeletonCard from "./SkeletonCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  FreeMode,
  Mousewheel,
} from "swiper/modules";

function FeatureSlider({ changeBackground }) {
  const swiperRef = useRef(null);
  const {
    featuredSuccess,
    featuredCollections,
    featuredloading,
    featuredError,
  } = useSelector((state) => state.collection);

  const [currentIndex, setCurrentIndex] = useState(0);

  const maxIndex = featuredCollections
    ? featuredCollections.length - /* Number of slides per view */ 4
    : 0;

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
    changeBackground(swiper.realIndex);
  };

  return (
    <div className="h-full w-full relative">
      {featuredSuccess && (
        <>
          <button
            id="previousButton"
            type="button"
            className={`prev hover:text-white absolute top-1/2 translate-y-[-50%] left-2 lg:-left-6 w-[48px] h-[48px] z-20 bg-primary-light dark:bg-primary-dark shadow-md rounded-full cursor-pointer ${
              currentIndex === 0 ? "opacity-50 pointer-events-none" : ""
            }`}
            onClick={() => swiperRef.current.swiper.slidePrev()}
            disabled={currentIndex === 0}
          >
            <MdKeyboardArrowLeft className="w-full h-full text-secondary-dark dark:text-secondary-light" />
          </button>
          <button
            id="nextButton"
            type="button"
            className={`next hover:text-white absolute top-1/2 translate-y-[-50%] right-2 lg:-right-6 w-[48px] h-[48px] z-20 bg-primary-light dark:bg-primary-dark shadow-md rounded-full cursor-pointer ${
              currentIndex >= maxIndex ? "opacity-50 pointer-events-none" : ""
            }`}
            onClick={() => swiperRef.current.swiper.slideNext()}
            disabled={currentIndex >= maxIndex}
          >
            <MdKeyboardArrowRight className="w-full h-full text-secondary-dark dark:text-secondary-light" />
          </button>
          <div>
            <Swiper
              ref={swiperRef}
              id="carousel"
              modules={[
                Navigation,
                Pagination,
                Scrollbar,
                A11y,
                Autoplay,
                FreeMode,
                Mousewheel,
              ]}
              // loopFillGroupWithBlank={true}
              navigation={{
                prevEl: "#previousButton",
                nextEl: "#nextButton",
              }}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              onSlideChange={handleSlideChange}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                  slidesPerGroup: 1,
                  centeredSlides: true,
                  spaceBetween: 8,
                },
                600: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  centeredSlides: false,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  centeredSlides: false,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                  centeredSlides: false,
                  spaceBetween: 15,
                },
                2256: {
                  slidesPerView: 6,
                  slidesPerGroup: 6,
                  centeredSlides: false,
                  spaceBetween: 15,
                },
              }}
            >
              {!featuredloading &&
                featuredCollections?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <BannerCard item={item} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}

      {featuredloading && (
        <div className="mt-4">
          <Swiper
            ref={swiperRef}
            id="carousel"
            modules={[]}
            breakpoints={{
              0: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                centeredSlides: false,
                spaceBetween: 15,
              },
              600: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                centeredSlides: false,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                centeredSlides: false,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                centeredSlides: false,
                spaceBetween: 15,
              },
              2256: {
                slidesPerView: 6,
                slidesPerGroup: 6,
                centeredSlides: false,
                spaceBetween: 15,
              },
            }}
          >
            {[...Array(4)].map((_, index) => (
              <SwiperSlide key={index}>
                <SkeletonCard />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      {!featuredloading &&
        (featuredError ||
          !featuredCollections ||
          featuredCollections.length === 0) && (
          <h3 className="flex items-center justify-center h-[30vh] text-[#d9d9d9] text-[22px] font-semibold m-0">
            No Items Found
          </h3>
        )}
    </div>
  );
}

export default FeatureSlider;
