import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getNftById } from "../../redux/reducers/collection";
import { BigNumber } from "bignumber.js";

import NFTImage from "./NFTImage";
import NFTActionArea from "./NFTActionArea";
import NFTActivity from "./NFTActivity";
import NFTSiblings from "./NFTSiblings";

BigNumber.config({ EXPONENTIAL_AT: 257 });

function NFTDetailPage(props) {
  const dispatch = useDispatch();
  const { address, errorMsg } = useSelector((state) => state.user);
  const { venomInstance, venomProvider } = useSelector((state) => state.venom);
  const { collectionId, nftAddress } = useParams();

  useEffect(() => {
    dispatch(
      getNftById({ collectionAddress: collectionId, nftAddress: nftAddress })
    );

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [collectionId, nftAddress]);

  return (
    <div className="mt-3 mb-3em w-full px-[1em] ">
      <div className="grid grid-cols-1 md:grid-cols-[40%,60%] pt-[5em] gap-4">
        <NFTImage />
        <NFTActionArea />
      </div>
      <NFTActivity />
      <NFTSiblings />
    </div>
  );
}

export default NFTDetailPage;
