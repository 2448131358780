import { createSlice } from "@reduxjs/toolkit";

export const venom = createSlice({
  name: "venom",
  initialState: {
    venomInstance: { on: () => {}, checkAuth: () => {}, getStandalone: () => {} },
    venomProvider: {},
    standaloneProvider: {},
    walletError: null,
  },
  reducers: {
    setVenomInstance: (state, action) => {
      state.venomInstance = action.payload;
    },
    setVenomProvider: (state, action) => {
      state.venomProvider = action.payload;
    },
    setStandaloneProvider: (state, action) => {
      state.standaloneProvider = action.payload;
    },
    setWalletError: (state, action) => {
      state.walletError = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVenomInstance, setVenomProvider, setStandaloneProvider } = venom.actions;

export default venom.reducer;
