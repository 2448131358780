import React from 'react'
import pagenotfound from "../utils/svgs/pagenotfound.jpg"

const PageNotFound = () => {
  return (
    <div>
      <div className="flex w-full h-full">
        <img className=" w-full h-full" src={pagenotfound} alt="" />
      </div>
    </div>
  );
}

export default PageNotFound