import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import SvgIcons from "../../utils/svgs/SvgIcons";

const ListingModal = ({
  open,
  handleClose,
  handleList,
  nft,
  isListLoading,
}) => {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (!open) {
      setPrice(0);
    }
  }, [open]);

  const handleClick = (event) => {
    if (!price || price == 0) {
      return;
    }
    console.log("lsitign item for sale------------");
    // call parent function;
    handleList(price);
  };

  const handleChange = (event) => {
    // Remove leading zeros
    let inputValue = event.target.value.replace(/^0+/, '');

    // Remove non-digit characters except a leading minus sign for negative numbers
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    // Limit input to 6 decimal places
    if (inputValue.includes('.')) {
      const parts = inputValue.split('.');
      if (parts[1].length > 6) {
        inputValue = `${parts[0]}.${parts[1].slice(0, 6)}`;
      }
    }

    // Limit input to positive numbers
    if (parseFloat(inputValue) < 0 || inputValue === '.') {
      inputValue = '';
    }

    // Limit input to 1 million
    if (parseFloat(inputValue) > 1000000) {
      inputValue = '1000000';
    }

    setPrice(inputValue);
  };

  return (
    <>
      <Modal
        open={open}
        // onOk={handleList} //Listing/
        onCancel={handleClose}
        footer={false}
      >
        <div className=" h-[570px]">
          <div className="block max-w-screen-xl items-center my-8 border-b-2">
            <div className=" max-w-screen-xl items-center mx-8">
              <div>
                <p className=" text-xl font-Poppins font-bold my-2">
                  List for Sale
                </p>
                <div className=" bg-[#D9D9D9] h-[45px] flex items-center rounded">
                  <div className=" cursor-pointer h-[32px] w-[132px] ml-4 bg-[#F4F4F4] flex items-center rounded">
                    <p className=" text-[#424242] mx-2 font-semibold text-[16px] ">
                      Fixed Price
                    </p>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.71249 17C9.71291 17 9.71343 17 9.71394 17C10.246 16.9996 10.7462 16.7921 11.1223 16.4154L16.419 11.1115C17.1937 10.3354 17.1937 9.07255 16.419 8.29653L9.27895 1.14333C8.54314 0.406116 7.5641 0 6.52228 0H1.99233C0.893848 0 0 0.893579 0 1.99219V6.50792C0 7.54894 0.405729 8.5275 1.14227 9.26315L8.30448 16.4174C8.68074 16.7931 9.18079 17 9.71249 17ZM6.52228 1.32812C7.2088 1.32812 7.85392 1.59572 8.33882 2.08152L15.4789 9.23472C15.7372 9.4935 15.7372 9.91435 15.4789 10.1731L10.1822 15.477C10.057 15.6025 9.89035 15.6718 9.71291 15.6719H9.71239C9.53515 15.6719 9.3685 15.6029 9.24315 15.4777L2.08094 8.32361C1.59552 7.83884 1.32822 7.19387 1.32822 6.50792V1.99219C1.32822 1.62602 1.62613 1.32812 1.99233 1.32812H6.52228ZM4.81489 6.74023C5.91348 6.74023 6.80722 5.84666 6.80722 4.74805C6.80722 3.64944 5.91348 2.75586 4.81489 2.75586C3.71631 2.75586 2.82257 3.64944 2.82257 4.74805C2.82257 5.84666 3.71631 6.74023 4.81489 6.74023ZM4.81489 4.08398C5.18109 4.08398 5.479 4.38188 5.479 4.74805C5.479 5.11422 5.18109 5.41211 4.81489 5.41211C4.4486 5.41211 4.15079 5.11422 4.15079 4.74805C4.15079 4.38188 4.4486 4.08398 4.81489 4.08398Z"
                        fill="#424242"
                      />
                    </svg>
                  </div>
                </div>
                <p>Please complete listing</p>
              </div>
              <div className="flex flex-row mt-6 my-8">
                <div className=" h-[50px] w-[50px] bg-slate-700 rounded-lg">
                  <img src={nft.nftImage}></img>
                </div>
                <div className=" ml-3">
                  <p className="font-Poppins font-bold text-lg text-[#2A2A2A] ">
                    {`${nft.collectionName} # ${nft.nftId}`}
                  </p>
                  <div className="flex flex-row font-medium font-[#2B2A2A]">
                    <span className=" text-base">{nft.collectionName}</span>

                    <SvgIcons.Checked />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block max-w-screen-xl items-center mx-8">
            <div className="flex justify-between">
              <p className=" text-[16px] font-medium	">Collection Floor</p>
              <div className="flex">
                <div className=" h-[20px] w-[20px] mx-2 bg-slate-600 rounded-full"></div>
                <p className=" font-[#2A2A2A] font-medium text-base">
                  {`${nft.collection?.floorPrice} Venoms`}
                </p>
              </div>
            </div>
            <div className=" bg-[#D9D9D9] h-[45px] my-6 flex items-center rounded">
              <input
                type="number"
                className="bg-[#D9D9D9] h-[45px] my-6  items-center rounded  border-gray-300 text-gray-600 text-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Price"
                value={price}
                maxLength={1000000}
                onChange={handleChange}
                required
              ></input>
            </div>
            <div>
              <p className=" text-xl font-Poppins font-bold my-2">Fee</p>
              <div className="flex justify-between my-2">
                <p className=" text-[16px] font-medium	">
                  {`Creator Royalties (${nft.collection?.royality || 0}%)`}
                </p>
                <div className="flex">
                  <div className=" h-[20px] w-[20px] mx-2 bg-slate-600 rounded-full"></div>
                  <p className=" font-[#2A2A2A] font-medium text-base">
                    {`${
                      (price * Number(nft.collection?.royality || 0)) / 100
                    } VENOMS`}
                  </p>
                </div>
              </div>
              <div className="flex justify-between">
                <p className=" text-[16px] font-medium	">Platform(2.0%)</p>
                <div className="flex">
                  <div className=" h-[20px] w-[20px] mx-2 bg-slate-600 rounded-full"></div>
                  <p className=" font-[#2A2A2A] font-medium text-base">
                    {`${(price * 2) / 100} VENOMS`}
                  </p>
                </div>
              </div>
              <div className="grid justify-items-stretch h-12 gap-4 grid-cols-2 my-12">
                <div
                  className="flex justify-center items-center cursor-pointer bg-[#D9D9D9] rounded-2xl "
                  onClick={handleClose}
                >
                  <span className="text-[20px] text-[#7E838C] font-bold">
                    Cancel
                  </span>
                </div>
                <button
                  className={
                    !price || price == 0
                      ? "flex justify-center items-center cursor-pointer bg-[#D9D9D9] rounded-2xl "
                      : "flex justify-center items-center cursor-pointer bg-[#1D6C64] rounded-2xl "
                  }
                  // disabled={true}
                  onClick={handleClick}
                  disabled={isListLoading}
                >
                  <span className="text-[20px] text-white font-bold">
                    {isListLoading ? "Loading..." : "List"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ListingModal;
