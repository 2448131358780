const configuration = {
  testnet: {
    factoryDirectSell:
      "0:b2e2875069b92f8531ff766c2359af0b9c5cd4a08d416dd4515c073057b6a901",
    venom_network_id: 1000,
    venom_group: "test",
    venom_type: "jrpc",
    venom_network_endpoint: "https://jrpc-testnet.venom.foundation/rpc",
    paymentTokenAddress:
      "0:52078513adebf901c934265d28eb078a249522c12612ede70071128866bcb441",
    vaultAddress:
      "0:c85c07a3a6e1f2014ba40efd46dcc73467cf39c280d6005673ef63a97622830a",
  },
  mainnet: {
    // Add mainnet configurations here if needed
    factoryDirectSell:
      "0:cbcd6631920609f462cbd4a4ca5b415650b0410bba52d21ba068e07ba6b753f0",
    venom_network_id: 1,
    venom_group: "venom_mainnet",
    venom_type: "jrpc",
    venom_network_endpoint: "https://jrpc.venom.foundation/rpc",
    paymentTokenAddress:
      "0:ad7ad0023853d6a5cfb6a48912f184170dd0d20eb06981b91820970f27204e5d",
    vaultAddress:
      "0:94b955c3a78afa431ec3755bfa50ec058f4c0cd968e592cc9e62d904f5aeec75",
  },
};

const getConfig = (network) => {
  return configuration[network];
};

// Enable for testnet
export const environment = getConfig("mainnet");

//Enable for mainnet
// export const environment = getConfig("mainnet");
