export const terms_Of_Services = ` <h2 class="mb-4">Last Updated: September 3, 2023</h2>
    
<h3 class="mb-2 font-extrabold text-2xl">1. Acceptance of Terms</h3>
<p class="mb-4">Welcome to VenomSea ("we," "us," "our"). By using our NFT marketplace, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use our services.</p>

<h3 class="mb-2 font-extrabold text-2xl">2. User Registration</h3>
<h4 class="mb-1 ">2.1. Eligibility</h4>
<p class="mb-4">To use our platform, you must be of legal age in your jurisdiction and have the legal capacity to enter into contracts. Minors are not permitted to use our services.</p>

<h4 class="mb-1 ">2.2. Account Information</h4>
<p class="mb-4">You are responsible for providing accurate and complete registration information. You agree to keep your login credentials confidential and to notify us immediately of any unauthorized use of your account.</p>

<h3 class="mb-2 font-extrabold text-2xl">3. Platform Usage</h3>
<h4 class="mb-1 ">3.1. License</h4>
<p class="mb-4">We grant you a limited, non-exclusive, non-transferable license to use our platform for personal, non-commercial purposes, subject to these Terms and Conditions.</p>

<h4 class="mb-1 ">3.2. Prohibited Activities</h4>
<p class="mb-4">You agree not to engage in any activities that violate applicable laws or our policies, including but not limited to fraud, unauthorized access, harassment, or the creation or distribution of harmful content.</p>

<h4 class="mb-1 ">3.3. Intellectual Property</h4>
<p class="mb-4">All content and materials on our platform, including NFTs, are protected by intellectual property rights. You may not use, reproduce, or distribute such content without proper authorization.</p>
 
<h3 class="mb-2 font-extrabold text-2xl">4. NFT Transactions</h3>
<h4 class="mb-1 ">4.1. Listing and Purchasing</h4>
<p class="mb-4">Users may list NFTs for sale on our platform. Buyers may purchase NFTs. Transactions are subject to fees, which will be clearly communicated.</p>

<h4 class="mb-1 ">4.2. Ownership</h4>
<p class="mb-4">Upon purchase, buyers gain ownership rights to the NFT but do not acquire any rights to the underlying intellectual property. Sellers are responsible for ensuring they have the necessary rights to sell NFTs.</p>

<h4 class="mb-1 ">4.3. Blockchain Transactions</h4>
<p class="mb-4">NFTs on our platform are blockchain-based. Users are responsible for transaction fees and compliance with blockchain protocols.</p>

<h3 class="mb-2 font-extrabold text-2xl">5. Privacy Policy</h3>
<p class="mb-4">Our Privacy Policy outlines how we collect, use, and safeguard your data. By using our platform, you consent to our data practices as described in the Privacy Policy.</p>

<h3 class="mb-2 font-extrabold text-2xl">6. Disclaimers and Liability</h3>
<h4 class="mb-1 ">6.1. Platform Availability</h4>
<p class="mb-4">We strive to provide uninterrupted service but do not guarantee continuous availability. We are not liable for service interruptions.</p>

<h4 class="mb-1 ">6.2. Content Accuracy</h4>
<p class="mb-4">We do not guarantee the accuracy or completeness of content on our platform. Users should verify information independently.</p>

<h4 class="mb-1 ">6.3. Limitation of Liability</h4>
<p class="mb-4">To the extent permitted by law, we are not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our platform.</p>

<h3 class="mb-2 font-extrabold">7. Changes to Terms and Conditions</h3>
<p class="mb-4">We reserve the right to modify or update these Terms and Conditions at any time. Changes will be posted on our website, and your continued use of our platform constitutes acceptance of the revised terms.</p>

<h3 class="mb-2 font-extrabold text-2xl">8. Termination</h3>
<p class="mb-4">We may terminate or suspend your account or access to our platform for violation of these terms or any other reason, with or without notice.</p>

<h3 class="mb-2 font-extrabold text-2xl">9. Contact Us</h3>
<p>If you have questions, concerns, or requests regarding these Terms and Conditions, please contact us at <a href="mailto:askvenomsea@gmail.com">askvenomsea@gmail.com</a>.</p>`;

export const privacy_policy = `<h2 class="mb-4">Last Updated: September 3, 2023</h2>

<h3 class="mb-2 font-extrabold text-2xl">1. Introduction</h3>
<p class="mb-4">Welcome to VenomSea ("we," "us," "our"). At VenomSea, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our services.</p>

<h3 class="mb-2 font-extrabold text-2xl">2. Information We Collect</h3>

<h4 class="mb-1">2.1. User-Provided Information</h4>
<p class="mb-4">We may collect personal information you provide to us when you create an account, make transactions, or contact our support team. This information may include your name, email address, wallet address, and other details.</p>

<h4 class="mb-1">2.2. Automatically Collected Information</h4>
<p class="mb-4">We may collect certain information automatically when you use our platform, such as IP addresses, device information, and browsing activity. We may use cookies and similar technologies for this purpose.</p>

<h3 class="mb-2 font-extrabold text-2xl">3. How We Use Your Information</h3>

<h4 class="mb-1">3.1. To Provide Services</h4>
<p class="mb-4">We use your information to offer, maintain, and improve our NFT marketplace services, including processing transactions and providing customer support.</p>

<h4 class="mb-1">3.2. To Communicate</h4>
<p class="mb-4">We may contact you regarding your account, transactions, updates, and promotional offers.</p>

<h4 class="mb-1">3.3. To Enhance Security</h4>
<p class="mb-4">We use your data to monitor and enhance the security of our platform, detect and prevent fraud, and comply with legal obligations.</p>

<h3 class="mb-2 font-extrabold text-2xl">4. Information Sharing</h3>
<p class="mb-4">We do not sell, rent, or trade your personal information to third parties without your consent. However, we may share your data in the following circumstances:</p>

<h4 class="mb-1">4.1. With Service Providers</h4>
<p class="mb-4">We may share information with trusted service providers who assist us in providing and maintaining our services.</p>

<h4 class="mb-1">4.2. Legal Compliance</h4>
<p class="mb-4">We may disclose your information to comply with legal requirements, enforce our policies, or protect our rights, privacy, safety, or property.</p>

<h3 class="mb-2 font-extrabold text-2xl">5. Your Choices</h3>
<p class="mb-4">You have the right to:</p>

<h4 class="mb-1">5.1. Access, Update, or Delete Data</h4>
<p class="mb-4">You may access, update, or delete your personal information by logging into your account or contacting us.</p>

<h4 class="mb-1">5.2. Opt-Out</h4>
<p class="mb-4">You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in emails.</p>

<h3 class="mb-2 font-extrabold text-2xl">6. Data Security</h3>
<p class="mb-4">We take reasonable measures to protect your data from unauthorized access or disclosure. However, please be aware that no data transmission over the internet or storage method is entirely secure.</p>

<h3 class="mb-2 font-extrabold text-2xl">7. Changes to this Privacy Policy</h3>
<p class="mb-4">We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. The latest version will be posted on our website.</p>

<h3 class="mb-2 font-extrabold text-2xl">8. Changes to This Privacy Policy</h3>
<p class="mb-4">This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at <a href="https://venomsea.io/privacy">https://venomsea.io/privacy</a>. The date the Privacy Policy was last revised is identified at the beginning of this Privacy Policy. You are responsible for periodically visiting our Service and this Privacy Policy to check for any changes.</p>

<h3 class="mb-2 font-extrabold text-2xl">9. Contact Us</h3>
<p>If you have any questions, concerns, or requests regarding this Privacy Policy or your data, please contact us at <a href="mailto:askvenomsea@gmail.com">askvenomsea@gmail.com</a>.</p>`;

export const community_standard = ` <h2 class="mb-4 font-extrabold">Our Moderation Principles</h2>
    
<p class="mb-4">The permanence and portability inherent to NFT technology offer unprecedented opportunities for ownership and expression across the web3-enabled internet. However, these features also mean that offensive, distasteful, and problematic NFTs may appear on blockchains. Given the decentralized nature of blockchain technology, neither individuals nor platforms, including VenomSea, have the ability to remove content from blockchains.</p>

<p class="mb-4">As a platform, we are tasked with deciding what content to reflect while fostering a trustworthy environment. As leaders in this ecosystem, we have developed content moderation policies and enforcement mechanisms to guide the content discoverable and purchasable through our services.</p>

<p class="mb-4">At its core, our content moderation approach reflects our role as a blockchain explorer. We aim to provide users with a comprehensive view of the NFT ecosystem, emphasizing neutrality with interventions and enforcement proportionate to real-world harm potential. Our approach is precise, focusing on specific enforcement actions, rather than broad generalizations, and aligns with our role as a blockchain explorer.</p>

<p class="mb-4">Within this framework, our content moderation policies address four general categories of behavior that could negatively impact our users: physical and emotional harm, financial harm, poor user experience, and intellectual property.</p>

<p class="mb-4">At VenomSea, we are committed to maintaining a safe, trustworthy, and inclusive platform where individuals can explore and own web3 assets. As we continue to evolve as a company and within the industry, we are dedicated to refining our policies to provide the best possible blockchain experience.</p>

<h3 class="mb-2 font-extrabold">Community Standards</h3>

<h4 class="mb-1 font-extrabold">1. Physical and Emotional Harm</h4>
<p class="mb-4">1.1. Inciting Violence: Content that facilitates, encourages, praises, or supports acts of violence, whether by terrorist organizations or individuals, is strictly prohibited. Additionally, disclosing private, personally identifiable information (doxxing) is not allowed.</p>

<p class="mb-4">1.2. Inciting Hate: We prohibit content that promotes hate organizations, advocates violence against, superiority over, or the nonexistence of protected groups. Content containing hateful stereotypes or slurs is also prohibited.</p>

<p class="mb-4">1.3. Sexual Exploitation: Content created or distributed without the subject's adult consent, such as revenge porn or non-consensual sexualized content, is not allowed.</p>

<p class="mb-4">1.4. Suicide and Self-Injury: While we value self-expression, content that glorifies, encourages, praises, or supports suicide or self-injury is not permitted.</p>

<p class="mb-4">1.5. Child Sexual Abuse Material (CSAM): VenomSea has a zero-tolerance policy for content sexualizing minors, whether real children or representations. Such content involving real children will be reported to the National Center for Missing and Exploited Children.</p>

<h4 class="mb-1 font-extrabold">2. Financial Harm</h4>
<p class="mb-4">2.1. Scams and Malicious Behavior: Activities that may result in the loss of user funds or NFTs, such as wallet draining, theft, malware distribution, links to unsecured sites, or failure to deliver promised items (e.g., rug pulls), are prohibited.</p>

<p class="mb-4">2.2. Fundraising: Some users may be restricted from fundraising on our platform, such as government officials and political candidates in official capacities. Eligible fundraisers may encounter restrictions on the types of causes or organizations they can support.</p>

<h4 class="mb-1 font-extrabold">3. User Experience</h4>
<p class="mb-4">3.1. Spam: Unsolicited content distributed for commercial/promotional or abusive/malicious purposes is not allowed. This includes mass airdrops to the same wallet or widespread distribution of the same item to numerous wallets.</p>

<p class="mb-4">3.2. Copymint: Items or collections that could be easily mistaken for prominent existing items or collections are prohibited. Users must make significant changes to the original work to avoid confusion.</p>

<p class="mb-4">3.3. Misleading Collections and Accounts: Content designed to confuse users into making a purchase, such as implying a relationship with a creator or falsely claiming affiliation with a popular collection, is not allowed.</p>

<p class="mb-4">3.4. Misleading Checkmarks: User-generated checkmarks similar to VenomSea's verified checkmark are prohibited.</p>

<p class="mb-4">3.5. NSFW (Not Safe for Work): Consensually created and distributed adult content is allowed, but it must be labeled NSFW to provide users with the choice to view it.</p>

<p class="mb-4">3.6. Burn Function: Creators must disclose any burn functionality in an item's smart contract. Some conditions for activating the burn function, such as burning an NFT for being listed below the floor price, are prohibited.</p>

<p class="mb-4">3.7. Inorganic Volume: Efforts to artificially inflate sales volume or floor price, including coordinated sales between wallets or manipulative activities, are prohibited. Collections with inorganic volume may be removed from the homepage, and, in some cases, accounts engaging in such behavior may be banned.</p>

<h4 class="mb-1 font-extrabold">4. Intellectual Property</h4>
<p class="mb-4">VenomSea does not tolerate content that violates the intellectual property rights of others, including trademark, copyright, and the right of publicity. We rely on rightsholders or their legal representatives to submit valid takedown requests.</p>

<h3 class="mb-2 font-extrabold">Actions We Take</h3>

<p class="mb-4">While VenomSea cannot delete or remove content from underlying blockchains, we employ various enforcement mechanisms to uphold our policies and community guidelines on the VenomSea platform. We tailor enforcement actions to perceived harm:</p>

<p class="mb-4">- We may choose to limit the visibility of content that, while not in direct violation of our policies or guidelines, could lead to a negative user experience on our platform.</p>
<p class="mb-4">- For content that violates our policies but may still be viewable, we can disable buying, selling, and transferring of specific NFTs or collections using VenomSea's services.</p>
<p class="mb-4">- In cases where content violates our policies and viewing it could result in harm to users, we may delist an item or collection entirely, rendering it inaccessible and undiscoverable on VenomSea.</p>

<p class="mb-4">As a blockchain explorer, we prefer to disable rather than delist content and reserve delisting for specific situations involving real-world harm or legal requirements. For example, we maintain a zero-tolerance policy for CSAM (child sexual abuse material) and promptly delist such content. Additionally, content created with the intent to deceive</p>`;

export const signString = `1. Introduction
Welcome to VenomSea ("we," "us," "our"). At VenomSea, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our services.

2. Information We Collect
2.1. User-Provided Information
We may collect personal information you provide to us when you create an account, make transactions, or contact our support team. This information may include your name, email address, wallet address, and other details.

2.2. Automatically Collected Information
We may collect certain information automatically when you use our platform, such as IP addresses, device information, and browsing activity. We may use cookies and similar technologies for this purpose.

3. How We Use Your Information
3.1. To Provide Services
We use your information to offer, maintain, and improve our NFT marketplace services, including processing transactions and providing customer support.

3.2. To Communicate
We may contact you regarding your account, transactions, updates, and promotional offers.

3.3. To Enhance Security
We use your data to monitor and enhance the security of our platform, detect and prevent fraud, and comply with legal obligations.

4. Information Sharing
We do not sell, rent, or trade your personal information to third parties without your consent. However, we may share your data in the following circumstances:

4.1. With Service Providers
We may share information with trusted service providers who assist us in providing and maintaining our services.

4.2. Legal Compliance
We may disclose your information to comply with legal requirements, enforce our policies, or protect our rights, privacy, safety, or property.

5. Your Choices
You have the right to:

5.1. Access, Update, or Delete Data
You may access, update, or delete your personal information by logging into your account or contacting us.

5.2. Opt-Out
You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in emails.

6. Data Security
We take reasonable measures to protect your data from unauthorized access or disclosure. However, please be aware that no data transmission over the internet or storage method is entirely secure.

7. Changes to this Privacy Policy
We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. The latest version will be posted on our website.

8. Changes to This Privacy Policy
This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at https://venomsea.io/privacy. The date the Privacy Policy was last revised is identified at the beginning of this Privacy Policy. You are responsible for periodically visiting our Service and this Privacy Policy to check for any changes.

9. Contact Us
If you have any questions, concerns, or requests regarding this Privacy Policy or your data, please contact us at askvenomsea@gmail.com.`;
