export const formatAddress = (address) => {
  if (!address) return "";

  const maxLength = 10;
  const shortenedAddress =
    address.length > maxLength
      ? `${address?.substring(0, 5)}...${address?.substring(address.length - 5)}`
      : address;

  return shortenedAddress;
};
