import axios from 'axios';

export const BASE_DOMAIN = (() => {
  if (window.location.hostname === 'localhost') return 'http://localhost:5001';
  else {
    //todo main url will be returned
    return "https://api.venomsea.io"
  }
})();

export const BASE_URL = BASE_DOMAIN;
export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 240000,
  httpAgent: true,
});
