import React from "react";
import ImageChecker from "../sharedComponents/Image";
import DefaultCover from "../utils/svgs/defaulCover.png";
import { useNavigate } from "react-router-dom";
import venomIcon from "../utils/svgs/v.svg";
const BannerCard = ({ item }) => {
  const navigate = useNavigate();
  const {
    collectionAddress,
    collectionImage,
    collectionName,
    floorPrice,
    // totalSupply,
    // description,
    // discord,
    // dropBG,
    // dropImgLink,
    // bannerImage,
    // by,
    // categories,
    // isFeatured,
    // isTopCollection,
    // lastSale,
    // listPercentage,
    // ownerAddress,
    // royality,
    // totalOwners,
    // totalVolume,
    // twitter,
    // website,
  } = item;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className={` px-3 mt-4 text-white`}
        onClick={() => navigate(`collections/${collectionAddress}`)}
      >
        <ImageChecker
          imageUrl={collectionImage}
          altImage={DefaultCover}
          styles={
            "cursor-pointer brightness-[0.7] h-[270px] w-[270px] gap-x-9 hover-zoom  rounded-[14px]  hover:scale-105 2xl:h-[390px] 2xl:w-[390px]"
          }
        />
        <div className="flex flex-col absolute ml-[1em] mt-[-3em]">
          <span className="font-bold text-sm drop-shadow-2xl">
            {collectionName && collectionName.length > 30
              ? `${collectionName?.substring(0, 30)}...`
              : collectionName}
          </span>
          <span className="font-bold text-sm flex items-center gap-2">
            Floor: {floorPrice}{" "}
            <img
              src={venomIcon}
              style={{ height: "13px", width: "13px" }}
              alt="V"
            />
          </span>
          {/* <span className="font-bold text-base">Total Items: {totalSupply}</span> */}
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
