import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { getNftPriceHistory } from "../../redux/reducers/collection";
import { useParams } from "react-router-dom";
import Loading from "../../common/loading";

Chart.register(CategoryScale);

function NFTPriceHistory() {
  const dispatch = useDispatch();
  const { nftAddress } = useParams();
  const { priceHistory } = useSelector((state) => state.collection);

  useEffect(() => {
    dispatch(
      getNftPriceHistory({
        nftAddress: nftAddress,
      })
    );
  }, [nftAddress]);

  const chartData = {
    labels: Array(10)
      .fill()
      .map((_, index) => index + 1),
    datasets: [
      {
        label: "Price",
        data: priceHistory?.nftActivity?.map((data) => data?.price) || [],
        backgroundColor: ["#2a71d0"],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Last 10 Sale Prices",
      },
      legend: {
        display: false,
      },
    },
  };

  if (!priceHistory) {
    return (
      <div className="flex w-full h-[80vh] items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="chart-container">
      {/* <h2 style={{ textAlign: "center" }}>Price History</h2> */}
      <Bar data={chartData} options={options} />
    </div>
  );
}

export default NFTPriceHistory;
