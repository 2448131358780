const { API } = require("./service");

export const callTransactionStatus = async (hash) => {
    try {
      const response = await API.post("/transactionStatus", { hash });
      const { data } = response;
      if (data.result) {
        return true; // Transaction successful
      } else {
        return false; // Transaction failed
      }
    } catch (error) {
      console.error("Error occurred while calling API:", error);
      // Handle error appropriately, you might want to throw it or return false indicating failure
      return false;
    }
  };

