import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../utils/service';
import { setModel } from './privacypolicy';
import { objectToFormData, trimString } from '../../utils/defaultFunctions';

// Create async thunks
export const walletConnection = createAsyncThunk(
  'users/walletConnection',
  async (venomWalletAddress, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.post(`/walletConnection`, {
        walletAddress: venomWalletAddress,
      });
      console.log(res);
      if (res.data) {
        if (!res.data.user.privacypolicy) {
          dispatch(setModel(true));
        }
        return res.data.result;
      } else {
        return rejectWithValue('No result found');
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Error in walletConnection');
    }
  }
);

export const setPrivacyPolicy = createAsyncThunk(
  '/privacypolicy',
  async (walletAddress, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.post(`/privacypolicy`, walletAddress);
      console.log(res, 'response modal....');
      //update user and set modal to false and show loading in button
      if (res.status === 200) {
        dispatch(setModel(false));
        return res.data.user;
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Error in walletConnection');
    }
  }
);

export const updateProfile = createAsyncThunk(
  "/users/updateUser",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      console.log("DB",payload)
      const formDataPayload = objectToFormData(payload);
      const res = await API.post(
        `/users/update/${payload.walletAddress}`,
        formDataPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      console.log("RS",res);
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error in walletConnection");
    }
  }
);

export const GetUserById = createAsyncThunk(
  'users/userProfile',
  async (walletAddress, { rejectWithValue }) => {
    try {
      const res = await API.get(`/users/${walletAddress}`);
      console.log(res);
      if (res.data.user) {
        return res.data.user;
      } else {
        return rejectWithValue('No result found');
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Error in walletConnection');
    }
  }
);

export const GetUserOwnedNfts = createAsyncThunk(
  'users/ownedNfts',
  async ({walletAddress,page, pageSize} ,{ rejectWithValue }) => {
    try {
      const res = await API.post(`/users/${walletAddress}/owned`, {
        page,
        pageSize,
      });   
      console.log("RES.DATA", res.data);  
      if (res.data.success) {
        
        return { nfts: res.data.nfts, totalNfts: res.data.totalCount };
      } else {
        return {};
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Error in walletConnection');
    }
  }
);

export const getUserActivity = createAsyncThunk(
  "user/getUserActivity",
  async (walletAddress, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.get(
        `/users/${walletAddress}/useractivity`
      );
      
      if (res.data.success) {
        console.log(res.data.userActivity,"data hereeee")
        return res.data.userActivity;
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const dAppUser = createSlice({
  name: 'dAppUser',
  initialState: {
    address: null,
    errorMsg: null,
    user: {},
    loader: false,
    ownnftloader: false,
    success: false,
    privacyError: '',
    privacyLoader: false,
    updateError: '',
    updateLoader: false,
    nfts: [],
    userActivity: [],
    userActivityLoader: false,
    totalCount: 0
  },
  reducers: {
    login: (state, action) => {
      state.address = action.payload;
      state.errorMsg = null;
    },
    logout: (state, action) => {
      state.address = null;
      state.errorMsg = null;
    },
    user: (state, action) => state.user,
    setPrivacyLoader: (state, { payload }) => {
      state.privacyLoader = payload;
    },
      setUpdateLoader: (state, {payload}) => {
        state.updateLoader = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(walletConnection.fulfilled, (state, { payload }) => {
      console.log('connection event sent to server');
    });
    builder
      .addCase(GetUserById.fulfilled, (state, { payload }) => {
        console.log("GetUserById", payload);
        state.user = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(GetUserOwnedNfts.fulfilled, (state, { payload }) => {
        console.log("GetOwnedNFts", payload);
        state.nfts = payload.nfts;
        state.totalCount = payload.totalNfts
        state.ownnftloader = false;
        state.success = true;
      })
      .addCase(setPrivacyPolicy.fulfilled, (state, { payload }) => {
        console.log("setPrivacyPolicy", payload);
        state.user = payload;
        state.privacyLoader = false;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        console.log("updateProfile", payload);
        state.update = payload;
        state.updateLoader = false;
      })
      .addCase(getUserActivity.fulfilled, (state, { payload }) => {
        const newPay = payload.map((el) => {
          return {
            ...el,
            from: trimString(el.from),
            _from: el.from,
            to: trimString(el.to),
            _to: el.to,
          };
        });
        state.userActivity = newPay;
      })
      .addCase(updateProfile.pending, (state) => {
        console.log("ispending updateProfile");
        state.updateLoader = true;
      })
      .addCase(GetUserById.pending, (state) => {
        console.log("ispending");
        state.user={};
        state.loader = true;
        state.success = false;
      })
      .addCase(GetUserOwnedNfts.pending, (state) => {
        console.log("ispending GetOwnedNFts");
        state.nfts=[]
        state.ownnftloader = true;
        state.success = false;
      })
      .addCase(getUserActivity.pending, (state) => {
        console.log("pending activity");
      })
      .addCase(setPrivacyPolicy.pending, (state) => {
        console.log("ispending setPrivacyPolicy");
        state.privacyLoader = true;
      })
      .addCase(GetUserById.rejected, (state) => {
        console.log("is rejected");
        state.user={};
        state.loader = false;
        state.success = false;
      })
      .addCase(GetUserOwnedNfts.rejected, (state) => {
        console.log("is rejected GetOwnedNFts");
        state.nfts=[]
        state.ownnftloader = false;
        state.success = false;
      })
      .addCase(setPrivacyPolicy.rejected, (state) => {
        console.log("is rejected");
        state.loader = false;
        state.privacyError = false;
      })
      .addCase(updateProfile.rejected, (state) => {
        console.log("is rejected");
        state.loader = false;
        state.updateError = false;
      })
      .addCase(getUserActivity.rejected, (state) => {
        console.log("rejected activity");
      });
      
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, setPrivacyLoader, setUpdateLoader } = dAppUser.actions;

export default dAppUser.reducer;
