import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNftAttributes } from "../../redux/reducers/collection";
import { useParams } from "react-router-dom";
import { Popover } from "antd";

const NFTTraits = () => {
  const dispatch = useDispatch();
  const { collectionId, nftAddress } = useParams();
  const { nftAttributes } = useSelector((state) => state.collection);

  useEffect(() => {
    dispatch(
      getNftAttributes({
        collectionAddress: collectionId,
        nftAddress: nftAddress,
      })
    );
  }, []);
  console.log(
    nftAttributes?.attributes?.attributes,
    "nftAttributes?.attributes?.attributes"
  );
  return (
    <div className="flex flex-wrap gap-2 lg:gap-4">
      {nftAttributes?.attributes?.attributes.map((items, id) => {
        return (
          <div
            key={id}
            className="bg-slate-300 text-[#707A83] rounded-md w-full lg:w-auto flex justify-center items-center flex-col p-2 gap-1 px-4 md:px-8"
          >
            <p className="font-bold">{items.trait_type}</p>

            <div className="font-bold">
              <Popover content={items.value}>
                {/* Wrap value in a span for styling */}
                <span className="font-bold text-black overflow-hidden whitespace-nowrap">
                  {`${items.value}`}
                </span>
              </Popover>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NFTTraits;
