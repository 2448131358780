import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { initVenomConnect } from "../venom/venom-wrapper";
import user, { login, logout } from "../redux/reducers/user";
import {
  setVenomInstance,
  setVenomProvider,
  setStandaloneProvider,
  setWalletError,
} from "../redux/reducers/venom";

import { walletConnection } from "../redux/reducers/user";
import { PostPrivacySign } from "../redux/reducers/user";
import { ProviderRpcClient } from "everscale-inpage-provider";

import logo from "../utils/svgs/logosvg.svg";
import MoneyWallet from "../utils/svgs/wallet-one.png";
import AccountIcon from "../utils/svgs/account_circle.png";
import AccountIConBlack from "../utils/svgs/account_icon_black.png";

import { PoweroffOutlined } from "@ant-design/icons";

import "./header.css";
import SearchComponent from "../sharedComponents/SearchComponent/SearchComponent";
import {
  MdOutlineMenu,
  MdClose,
  MdChevronRight,
  MdChevronLeft,
  MdCheck,
} from "react-icons/md";
import { menuPlusIcons, navLinks, socials } from "../common/constants";
import { useLocation } from "react-router-dom";
import { scrollTop } from "../utils/commonFunctions";
// import { setModel } from "../redux/reducers/privacypolicy";

function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sideBarRef = useRef(null);
  const location = useLocation();
  // read data from redux
  const { venomInstance, venomProvider } = useSelector((state) => state.venom);
  const { address, errorMsg, user } = useSelector((state) => state.user);
  console.log(user);
  // const {privacypolicy} = user;
  // useEffect(() => {
  //   if(!privacypolicy && address){
  //      dispatch(setModel(true));
  //   }

  // }, [privacypolicy,address]);
  const [userBalance, setUserBalance] = useState("0");
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);

    // setBgNavbar((prev) => !prev)

    document.body.classList.toggle("overflow-y-hidden");
  };

  const onClickOutside = () => {
    setIsOpen(false);
    document.body.classList.remove("overflow-y-hidden");
  };
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (sideBarRef.current && !sideBarRef.current.contains(e.target)) {
        onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
  }, []);
  const getBalanceFunc = async () => {
    try {
      const balance = await venomProvider?.getBalance(address);
      const formattedBalance = (balance / 1000000000).toFixed(2).toString();
      setUserBalance(formattedBalance);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (venomProvider && address) {
      getBalanceFunc();
    }
  }, [venomProvider, address]);

  // initialize venom Connect on loading component
  const init = async () => {
    const _venomConnect = await initVenomConnect();
    dispatch(setVenomInstance(_venomConnect));
  };

  const initStandalone = async () => {
    const standalone = await venomInstance?.getStandalone();
    dispatch(setStandaloneProvider(standalone));
  };

  // This method allows us to gen a wallet address from inpage provider
  const getAddress = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };

  // When our provider is ready, we need to get address and balance from.
  const onProviderReady = async (provider) => {
    const venomWalletAddress = provider
      ? await getAddress(provider)
      : undefined;
    dispatch(login(venomWalletAddress || null));
    // dispatch redux event to let server know wallet is connected.
    dispatch(walletConnection(venomWalletAddress));
  };

  const onConnect = async (provider) => {
    console.log(provider, "provider");
    dispatch(setVenomProvider(provider));
    await onProviderReady(provider);
  };

  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async (_venomConnect) => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // Handling click of login button. We need to call connect method of out VenomConnect instance, this action will call other connect handlers
  const onLogin = async () => {
    if (!venomInstance) return;
    await venomInstance.connect();
  };

  const onLogout = async () => {
    venomProvider?.disconnect();
    // remove address from redux
    dispatch(logout());
  };

  useEffect(() => {
    // connect event handler
    const off = venomInstance?.on("connect", onConnect);
    if (venomInstance) {
      initStandalone();
      checkAuth(venomInstance);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomInstance]);

  useEffect(() => {
    init();
  }, []);

  const lastScrollTop = useRef(0);

  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (
      (window.scrollY > 0 && location.pathname.length === 1) ||
      (window.scrollY > 0 && location.pathname.includes("collections")) ||
      location.pathname.length > 100 ||
      location.pathname === "drops" ||
      location.pathname === "/drops" ||
      location.pathname === "/drops/" ||
      (window.scrollY > 0 &&
        (location.pathname != "drops" ||
          location.pathname != "/drops" ||
          location.pathname != "/drops/")) ||
      location.pathname.includes("setting") ||
      location.pathname === "/stats" ||
      location.pathname === "/stats/" ||
      location.pathname.includes("privacy_policy") ||
      location.pathname.includes("/community_standard") ||
      isOpen ||
      location.pathname.includes("terms_of_services")
    ) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, scrolling, location.pathname, isOpen]);

  return (
    <>
      <nav
        className={`z-50  ${
          scrolling ? "bg-[white]" : "bg-[transparent]"
        } px-[0.5em] xl:px-[2em] border-none	 flex items-center justify-between w-[100%] h-[72px]  fixed gap-[10px]  ${
          isNavbarVisible ? "visible" : ""
        }`}
      >
        <div className="flex items-center ">
          <img
            src={logo}
            alt="icon"
            onClick={() => navigate("/")}
            className="cursor-pointer w-[130px] h-[38.68px] "
          />
        </div>
        <div className="flex items-center gap-4 ">
          <div className="hidden xl:flex gap-3 ">
            <span
              className={`${
                scrolling ? "text-black" : "text-white"
              }   font-medium cursor-pointer text-xs sm:text-base md:text-lg lg:text-lg`}
              // onClick={() => navigate("/drops")}
            >
              Drops
            </span>
            <span
              className={`${
                scrolling ? "text-black" : "text-white"
              } font-medium cursor-pointer text-xs sm:text-base md:text-lg lg:text-lg`}
              onClick={() =>{ navigate("/stats")
              scrollTop()
            }}
            >
              Stats
            </span>
          </div>
          <SearchComponent scrolling={scrolling} />
        </div>

        <div className=" hidden xl:flex items-center ">
          {!address ? (
            <>
              <div
                className="flex  text-white justify-center items-center"
                onClick={onLogin}
              >
                <div className="cursor-pointer connect-btn flex justify-center items-center w-[20vw] h-[33px] rounded-md gap-[10px] lg:w-[15vw] mr-3 ">
                  <div>
                    <img src={MoneyWallet} alt="alt" />
                  </div>

                  <span className="text-xs sm:text-base md:text-lg lg:text-xl">
                    Connect
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-2">
                <div className="flex  text-white justify-center items-center">
                  <div
                    className="rounded-ss-lg rounded-es-lg cursor-pointer w-auto px-[1em] connect-btn flex justify-center items-center w-[188px] h-[33px] rounded-[4px, 0px, 0px, 4px] gap-[10px]"
                    // onClick={onLogin}
                  >
                    <div>
                      <img src={MoneyWallet} alt="alt" />
                    </div>

                    <span>{userBalance} VENOM</span>
                    {/* <span>0 WVENOM</span> */}
                  </div>
                  <div
                    className="px-[1em] flex items-center cursor-pointer  border border-[1.5px] border-[#5B68DF] border-y-[#5B68DF]   h-[33px] rounded-r-[0.5em]"
                    onClick={() => navigate(address)}
                  >
                    <img
                      src={scrolling ? AccountIcon : AccountIConBlack}
                      alt=""
                      className="cursor-pointer"
                    />
                  </div>
                </div>
                {/**for the time logout button will be card button */}
                <div
                  className="px-[1em] flex items-center cursor-pointer  border border-[1.5px] border-[#5B68DF] h-[33px] rounded-[0.5em]"
                  onClick={onLogout}
                >
                  {/* <img src={ShoppingCartIcon} alt='' /> */}
                  <PoweroffOutlined
                    style={{ color: `${scrolling ? "black" : "white"}` }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {/* mobile view menu */}
        <div className="flex xl:hidden items-center relative ">
          <div>
            <div onClick={toggleMenu} className="px-2 ">
              {isOpen ? (
                <MdClose
                  className={`${
                    scrolling ? "text-black" : "text-white"
                  } w-[32px] h-[32px] cursor-pointer hover:opacity-75 duration-300`}
                />
              ) : (
                <MdOutlineMenu
                  className={`${
                    scrolling ? "text-black" : "text-white"
                  } w-[32px] h-[32px] cursor-pointer hover:opacity-75 duration-300`}
                />
              )}
            </div>
            <div
              className={`${
                isOpen
                  ? "h-screen w-full top-[72px] left-0 fixed backdrop-blur-sm brightness-50 shadow-2xl"
                  : ""
              }`}
            />
            <aside
              ref={sideBarRef}
              className={`${
                isOpen ? "translate-x-0 duration-300" : "translate-x-full"
              } fixed min-h-screen bg-white w-full md:w-[400px] shadow-lg top-[72px] right-0 z-[99] `}
              // style={{ height: 'calc(100% - 72px)' }}
            >
              <div className="flex flex-col justify-between min-h-[95vh] md:h-[97vh] bg-white px-4 py-4 ">
                <div>
                  <ul>
                    <li className="flex h-[72px] px-4 pt-4 cursor-pointer hover:text-[#5f79d7]">
                      <a className="flex flex-auto items-center ">
                        <img
                          className="w-6 h-6 mr-3"
                          src={menuPlusIcons.drop.icon}
                          alt=""
                        />

                        <h4 className="font-semibold w-full">
                          {menuPlusIcons.drop.title}
                        </h4>
                      </a>
                    </li>
                    <li
                      className="flex h-[72px] px-4 pt-4 cursor-pointer hover:text-[#5f79d7]"
                      onClick={() => {
                        navigate("/stats");
                        setIsOpen(!isOpen);
                        scrollTop();
                      }}
                    >
                      <a className="flex flex-auto items-center ">
                        <img
                          className="w-6 h-6 mr-3"
                          src={menuPlusIcons.stats.icon}
                          alt=""
                        />

                        <h4 className="font-semibold w-full">
                          {menuPlusIcons.stats.title}
                        </h4>
                      </a>
                    </li>
                    <li className="flex h-[72px] px-4 pt-4 cursor-pointer hover:text-[#5f79d7]"   onClick={() => {
                        navigate("/stats");
                        setIsOpen(!isOpen);
                      }}>
                      <a className="flex flex-auto items-center ">
                        <img
                          className="w-6 h-6 mr-3"
                          src={menuPlusIcons.ranking.icon}
                          alt=""
                        />

                        <h4 className="font-semibold w-full">
                          {menuPlusIcons.ranking.title}
                        </h4>
                      </a>
                    </li>
                    <li className="flex h-[72px] px-4 pt-4 cursor-pointer hover:text-[#5f79d7]">
                      <a className="flex flex-auto items-center ">
                        <img
                          className="w-6 h-6 mr-3 fill-slate-100"
                          src={menuPlusIcons.profile.icon}
                          alt=""
                        />

                        <h4
                          className="font-semibold w-full"
                          onClick={() => {
                            if (!address) {
                              return onLogin();
                            }
                            setIsOpen(!isOpen);
                            navigate(address);
                          }}
                        >
                          {menuPlusIcons.profile.title}
                        </h4>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mb-4">
                  <div className="flex items-center place-content-center ">
                    {!address ? (
                      <>
                        <div className="p-4 w-[100%]" onClick={onLogin}>
                          <div className=" w-[100%] px-4 py-4 text-[20px] text-white font-bold  cursor-pointer connect-btn flex justify-center items-center h-[50px] rounded-md gap-[10px] ">
                            <div>
                              <img src={MoneyWallet} alt="alt" />
                            </div>

                            <span className="w-auto">Connect</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex gap-2">
                          <div className="flex  text-white justify-center items-center">
                            <div className="cursor-pointer w-auto px-[1em] connect-btn flex justify-center items-center w-[188px] h-[33px] rounded-[4px, 0px, 0px, 4px] gap-[10px]">
                              <div>
                                <img src={MoneyWallet} alt="alt" />
                              </div>

                              <span>{userBalance} VENOM</span>
                            </div>
                            <div
                              className="px-[1em] flex items-center cursor-pointer  border border-[1.5px] border-[#5B68DF] border-y-[#5B68DF]   h-[33px] rounded-r-[0.5em]"
                              onClick={() => {
                                setIsOpen(!isOpen);
                                navigate(address);
                              }}
                            >
                              <img
                                src={AccountIcon}
                                alt=""
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                          <div
                            className="px-[1em] flex items-center cursor-pointer  border border-[1.5px] border-[#5B68DF] h-[33px] rounded-[0.5em]"
                            onClick={onLogout}
                          >
                            <PoweroffOutlined
                              style={{
                                color: "black",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="flex items-center justify-center w-full h-[60px] border-t dark:border-gray-600 bottom-0 pb-4">
                    <div className="flex  gap-4">
                      <a
                        href="https://twitter.com/venomsea_"
                        target="_blank"
                        className=" p-2 [&>*]:w-[24px] [&>*]:h-[24px] p-2 text-gray-400 dark:text-gray-400 opacity-90 cursor-pointer"
                      >
                        {socials.twitter}
                      </a>
                      <a
                        href="https://discord.com/invite/venompunks"
                        target="_blank"
                        className=" p-2 [&>*]:w-[24px] [&>*]:h-[24px] p-2 text-gray-400 dark:text-gray-400 opacity-90 cursor-pointer"
                      >
                        {socials.discord}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
