import React from "react";

export const TrendingTableSkeleton = () => {
  return (
    <div className="mt-3 animate-pulse">
      <div className="border-t border-gray-100 w-full"></div>
      <div className="border-t border-gray-200 w-full"></div>
      <div className="overflow-x-auto">
        <table className="min-w-full border-transparent overflow-x-scroll ">
          <tbody className="bg-white border-transparent">
            <tr className="border-transparent ">
              <td className="px-8 border-transparent ">
                <div className="flex items-center ">
                  <div className="flex-shrink-0 h-20 w-20 bg-[#ececec]  ">
                    <img className="h-20 w-20 rounded-md " alt="" />
                  </div>
                  <div className="ml-4">
                    <div className="text-xl font-medium bg-[#ececec] w-[50px] h-[20px]"></div>
                    <div className="text-sm text-gray-500 bg-[#ececec] w-[100px] h-[20px]"></div>
                  </div>
                </div>
              </td>
              <td className="px-6 whitespace-nowrap border-transparent">
                <div className="text-xl font-medium text-Black bg-[#ececec] w-[100px] h-[40px]"></div>
              </td>
              <td className="px-6  whitespace-nowrap border-transparent">
                <div className="text-xl font-medium text-Black bg-[#ececec] w-[100px] h-[40px]"></div>
              </td>
              <td className="px-6 whitespace-nowrap border-transparent">
                <div className="text-xl font-medium text-Black bg-[#ececec] w-[100px] h-[40px]"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
