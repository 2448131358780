import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopCollections } from '../redux/reducers/collection';
import ExploreCard from '../common/exploreCard';

function ExoloreCategories() {
  const { categories } = useSelector((state) => state.categories);
  console.log(categories, 'categories');
  return (
    <div className="px-8  md:px-18 lg:px-18 mt-3 font-style">
      <p className=" text-[1.5em] font-extrabold text-black ">
        Explore Categories
      </p>
      <div className="flex flex-wrap gap-3 justify-center sm:justify-start  mt-4 pb-10">
        {categories.map((el, index) => {
          return (
            <ExploreCard
              categoryName={el.categoryName}
              categoryImage={el.categoryImage}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ExoloreCategories;
