import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { Collapse } from "antd";
import { MenuOutlined, CaretDownOutlined } from "@ant-design/icons";

import { getMoreFromThisCollection } from "../../redux/reducers/collection";

import NftCard from "../../common/Nftcard";

const genExtra = () => (
  <CaretDownOutlined
    onClick={(event) => {
      event.stopPropagation();
    }}
  />
);

function NFTSiblings(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { moreFromThisCollection } = useSelector((state) => state.collection);
  const { collectionId, nftAddress } = useParams();

  useEffect(() => {
    dispatch(
      getMoreFromThisCollection({
        collectionAddress: collectionId,
        nftAddress: nftAddress,
      })
    );
  }, [collectionId, nftAddress]);

  const handleClick = (e, nftAddress, collectionAddress) => {
    console.log(e, nftAddress);
    e.preventDefault();
    // Construct the relative URL
    const relativeUrl = `/collections/${collectionAddress}/${nftAddress}`;

    // Use navigate to perform navigation
    navigate(relativeUrl, { replace: false });
  };

  const renderFromThisCollectionNft = (moreFromThisCollection) => {
    return (
      moreFromThisCollection && (
        <div className="px-4 max-w-[1500px] grid grid-cols-2 mx-auto lg:grid-cols-4 md:grid-cols-3 gap-4 mx-4 mb-4">
          {moreFromThisCollection.map((el) => (
            <NftCard
              key={el.id} // Don't forget to add a unique key for each element in the list
              nft={el}
              handleClick={(e) =>
                handleClick(e, el.nftContractAddress, el.collectionAddress)
              }
              cardWitHeight={"w-[270px] h-[300px]"}
            />
          ))}
        </div>
      )
    );
  };

  const more = [
    {
      key: "1",
      label: <p className="font-bold text-md">More From this Collection</p>,
      // children: <p>{text}</p>,
      children: renderFromThisCollectionNft(moreFromThisCollection),
      extra: genExtra(),
    },
  ];

  return (
    <div className=" mt-1 pt-[1em] mb-[1em]">
      <Collapse
        accordion
        items={more}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (!isActive ? <MenuOutlined /> : "")}
        className="w-[fit]"
      />
    </div>
  );
}

export default NFTSiblings;
