import React, { useEffect } from "react";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import discordIcon from "../../utils/svgs/discord.svg";
import xicon from "../../utils/svgs/x.svg";
import verify from "../../utils/svgs/verified-icon.svg";
import MintSchedule from "./mintSchedule";

import { getDropById } from "../../redux/reducers/drop";
// import { drop } from "lodash";
import DropMinterComponent from "./dropMinter";

const mintingRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <DropMinterComponent />;
  } else {
    // Render a countdown
    return null;
  }
};

const Completionist = () => (
  <span className="bg-[#FFFFFF3D] p-2 rounded">Minting Live</span>
);

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  console.log(completed, "commm", days, hours, minutes, seconds);
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div className=" flex gap-2">
        <div className="bg-[#FFFFFF] bg-opacity-[45%] p-2 rounded-lg text-black w-[64px] h-[64px] text-center flex flex-col justify-center items-center">
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">{days}</div>
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">DAYS</div>
        </div>
        <div className="bg-[#FFFFFF] bg-opacity-[45%] p-2 rounded-lg text-black w-[64px] h-[64px] text-center flex flex-col justify-center items-center">
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">
            {hours}
          </div>
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">HR</div>
        </div>
        <div className="bg-[#FFFFFF] bg-opacity-[45%] p-2 rounded-lg text-black w-[64px] h-[64px] text-center flex flex-col justify-center items-center">
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">
            {minutes}
          </div>
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">MIN</div>
        </div>
        <div className="bg-[#FFFFFF] bg-opacity-[45%] p-2 rounded-lg text-black w-[64px] h-[64px] text-center flex flex-col justify-center items-center">
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">
            {seconds}
          </div>
          <div className="font-[600] font-[Poppins] text-[#FFFFFF]">SEC</div>
        </div>
      </div>
    );
  }
};

function DropDetailPage(props) {
  const dispatch = useDispatch();
  const { dropId } = useParams();
  const menu = ["Mint", "Roadmap", "FAQs"];

  const { drop } = useSelector((state) => state.drops);

  console.log(drop, "dropppp");

  const convertIntoMilliseconds = () => {
    const timestamp = drop.dropTimeStamp;
    const dateObject = new Date(timestamp);
    const milliseconds = dateObject.getTime();
    return milliseconds;
  };

  useEffect(() => {
    dispatch(getDropById({ dropId }));
  }, []);

  return (
    <div>
      <div className="relative h-500">
        <img
          src={drop.backgroudPicture ? drop.backgroudPicture : ""}
          alt="Background"
          className="w-full h-[400px] object-cover"
        />
        <div className="ml-8 mb-8 absolute bottom-4 left-4 text-white">
          <p className=" text-[38px] font-[Poppins] font-black ">
            {drop?.dropName}
          </p>
          <p className="font-semibold font-[Poppins] ">
            By: {drop.collectionDoc?.by}
          </p>
          <p className="font-semibold font-[Poppins]">
            Items : {drop.collectionDoc?.totalSupply}
          </p>
          <div className="flex items-center">
            {/* New container for countdown and <p> */}
            {drop.dropTimeStamp && (
              <Countdown
                date={new Date(drop.dropTimeStamp).getTime()}
                renderer={renderer}
              />
            )}

            {/* <Countdown date={Date.now() + 10000} /> */}
          </div>
        </div>
        <div className="absolute right-8 bottom-12 text-white flex gap-2">
          {/* <img src="" alt="website" /> */}
          <a
            href={drop.collectionDoc?.discord}
            target={
              drop.collectionDoc?.discord
                ? drop.collectionDoc?.discord
                : "_self"
            }
          >
            <img src={discordIcon} alt="discord" />
          </a>
          <a
            target={
              drop.collectionDoc?.twitter
                ? drop.collectionDoc?.twitter
                : "_self"
            }
            href={drop.collectionDoc?.twitter}
          >
            <img src={xicon} alt="x" />
          </a>
        </div>
      </div>
      <div className="flex mb-[32px] mt-4">
        {menu.map((detail, idx) => (
          <div
            key={idx}
            className="py-2 px-2 cursor-pointer mr-[10px] text-[14px] font-semibold rounded bg-detailColor1  md:px-8 md:mr-[30px] "
          >
            {detail}
          </div>
        ))}
      </div>
      <hr />
      <div className=" flex flex-col lg:flex-row bg-white p-4 md:flex">
        <div className="flex justify-center md:w-1/2  mb-4 md:mb-0  md:pr-4 ">
          <img
            src={drop.leftImage ? drop.leftImage : ""}
            alt="Image"
            className="w-[80%] h-[500px] rounded-xl"
          />
        </div>
        <div className="md:w-1/2  flex flex-col gap-2">
          <h2 className="text-xl font-semibold mb-2">About The Collection</h2>
          <div className="flex items-center gap-10">
            <img
              src={drop.leftImage ? drop.leftImage : ""}
              alt="img"
              className="w-[40px] h-[40px] rounded"
            />
            <div className=" flex font-extrabold gap-2">
              {drop?.dropName}
              <img src={verify} alt="img" className="w-[20px] h-[20px]" />
            </div>
            <p className="bg-[#F4F4F4] p-2 rounded font-semibold">Venom</p>
          </div>
          {/* <h2 className="text-xl font-semibold mb-2">{drop.rightH1}</h2> */}
          <p className="text-gray-600">{drop.rightPara1}</p>
          <h2 className="text-xl font-semibold mb-2">{drop.rightH2}</h2>
          <p className="text-gray-600">{drop.rightPara2}</p>
          {drop.dropTimeStamp && (
            <Countdown
              date={new Date(drop.dropTimeStamp).getTime()}
              // new Date("2023-10-28T21:05:00").getTime()
              renderer={({ days, hours, minutes, seconds, completed }) => {
                console.log("daaaa", days, hours, minutes, seconds, completed);
                if (completed) {
                  // Render a completed state

                  return <DropMinterComponent drop={drop} />;
                } else {
                  // Render a countdown
                  return null;
                }
              }}
            />
          )}

          <MintSchedule drop={drop}/>
        </div>
      </div>
    </div>
  );
}

export default DropDetailPage;
