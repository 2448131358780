import { Collapse } from "antd";
import { Link } from "react-router-dom";
import {
  MenuOutlined,
  CaretDownOutlined,
  TagOutlined,
  ShoppingCartOutlined,
  SketchOutlined,
} from "@ant-design/icons";

const DataTable = ({ data }) => {
  const renderIcons = {
    MINT: <TagOutlined />,
    TRANSFER: <ShoppingCartOutlined />,
    SALE: <SketchOutlined />,
    LIST: <ShoppingCartOutlined />,
  };
  return (
    <div className="w-[100%]">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Event
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Price
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              From
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              To
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Date
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item) => (
            <tr key={item._id}>
              <td className="px-6 py-4 whitespace-nowrap  font-bold">
                <div className="flex gap-2">
                  <span>{renderIcons[item.event]}</span>
                  <span>{item.event}</span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap  font-bold">
                {item.price}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[#2081E2] font-bold">
                {/* {trimString(item?.from.toString())} */}
                <Link to={`/${item._from}`}>{item.from}</Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[#2081E2]  font-bold">
                <Link to={`/${item._to}`}>{item.to}</Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap  font-bold">
                {item.date}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const genExtra = () => (
  <CaretDownOutlined
    onClick={(event) => {
      event.stopPropagation();
    }}
  />
);

function UserActivity({data}) {
  const itemActivity = [
    {
      key: "1",
      label: <p className="font-bold text-md">User Activities</p>,
      children: UserActivity && <DataTable data={data} />,
      extra: genExtra(),
    }

  ];

  return (
    <div className=" mb-8">
      <Collapse
        accordion
        items={itemActivity}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (!isActive ? <MenuOutlined /> : "")}
        className="w-[fit]"
      />
    </div>
  );
}

export default UserActivity;
