import { VenomConnect } from "venom-connect";
import { ProviderRpcClient } from "everscale-inpage-provider";
import { EverscaleStandaloneClient } from "everscale-standalone-client";
import { environment } from "../environment.config";

export const initVenomConnect = async () => {
  return new VenomConnect({
    theme: "dark",
    checkNetworkId: environment.venom_network_id,
    providersOptions: {
      venomwallet: {
        walletWaysToConnect: [
          {
            package: ProviderRpcClient,
            packageOptions: {
              fallback:
                VenomConnect.getPromise("venomwallet", "extension") ||
                (() => Promise.reject()),
              forceUseFallback: true,
            },
            packageOptionsStandalone: {
              fallback: () =>
                EverscaleStandaloneClient.create({
                  connection: {
                    id: 1000,
                    group: environment.venom_group,
                    type: environment.venom_type,
                    data: {
                      endpoint: [environment.venom_network_endpoint],
                    },
                  },
                }),
              // EverscaleStandaloneClient.create({
              //   connection: {
              //     id: 1,
              //     group: 'venom_mainnet',
              //     type: 'jrpc',
              //     data: {
              //       endpoint: 'https://jrpc.venom.foundation/rpc',
              //     },
              //   },
              // }),
              forceUseFallback: true,
            },

            id: "extension",
            type: "extension",
          },
        ],
        defaultWalletWaysToConnect: ["mobile", "ios", "android"],
      },
    },
  });
};
