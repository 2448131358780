import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/service";

// Create async thunks
export const getAllRankings = createAsyncThunk(
  "rankings/getAllRankings",
  async ({ interval }, { rejectWithValue }) => {

    try {
      const res = await API.get(`/rankings?interval=${interval}`);
      if (res.data.success) {
        return res.data.rankings;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error in walletConnection");
    }
  }
);


export const Rankings = createSlice({
  name: "Rankings",
  initialState: {
    rankings: [],
    loader: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRankings.fulfilled, (state, { payload }) => {
        console.log("rankings", payload);
        state.rankings = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllRankings.pending, (state) => {
        console.log("ispending rankings");
        state.loader = true;
        state.success = false;
      })
      .addCase(getAllRankings.rejected, (state) => {
        console.log("is rejected rankings");
        state.loader = false;
        state.success = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = Rankings.actions;

export default Rankings.reducer;
