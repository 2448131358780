import React from 'react';

const ContentPage = ({ heading, paragraph }) => {
  return (
    <div className=' mt-[70px] min-h-screen flex justify-center'>
      <div className='p-4 md:p-8 lg:p-16'>
        <h1 className='text-2xl md:text-4xl lg:text-6xl font-bold  text-blue-900 mb-4'>
          {heading}
        </h1>
        <div
          className='text-lg md:text-xl lg:text-2xl  text-gray-700'
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
        
      </div>
    </div>
  );
};

export default ContentPage;
