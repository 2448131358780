export const trimString = (inputStr) => {
  console.log("input", inputStr);
  if (inputStr) {
    const trimmedStr = inputStr.slice(0, 8) + "......." + inputStr.slice(-8);
    return trimmedStr;
  } else {
    return "";
  }
};

export const objectToFormData = (obj) => {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
};

export const timeAgo = (date) => {
  const timestamp = new Date(date).getTime();
  const secondsAgo = Math.floor((Date.now() - timestamp) / 1000);
  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
  };

  for (let interval in intervals) {
    const value = Math.floor(secondsAgo / intervals[interval]);
    if (value >= 1) {
      return value + " " + interval + (value === 1 ? "" : "s") + " ago";
    }
  }

  return "Just now";
};

export const formatTime = (dd) => {
  const timestamp = new Date(dd).getTime();
  const date = new Date(timestamp);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const am_pm = hours >= 12 ? "PM" : "AM";
  const hourFormat = hours % 12 || 12; // convert to 12-hour format

  return `${month} ${day}, ${year} at ${hourFormat}:${minutes
    .toString()
    .padStart(2, "0")} ${am_pm}`;
};
