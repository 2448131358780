import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../utils/service';

// Create async thunks
export const getAllCategories = createAsyncThunk(
  'categories/getAllCategories',
  async (_, { rejectWithValue }) => {
    try {
      const res = await API.get(`/categories`);
      console.log(res.data, 'hello');
      if (res.data.success) {
        return res.data.result;
      } else {
        return rejectWithValue('No result found');
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Error in walletConnection');
    }
  }
);

export const Categories = createSlice({
  name: 'Categories',
  initialState: {
    categories: [],
    loader: false,
    success: false,
    category: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.fulfilled, (state, { payload }) => {
        console.log('getAllCategories', payload);
        state.categories = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllCategories.pending, (state) => {
        console.log('ispending getAllCategories');
        state.loader = true;
        state.success = false;
      })
      .addCase(getAllCategories.rejected, (state) => {
        console.log('is rejected getAllCategories');
        state.loader = false;
        state.success = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = Categories.actions;

export default Categories.reducer;
