import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollectionById,
  resetCollectionDetail,
  getCollectionDetails,
  getCollectionNftActivites,
} from "../redux/reducers/collection";
import { useParams, useNavigate } from "react-router";
import CollectionCover from "../utils/svgs/defaulCover.png";
import CollectionProfile from "../utils/svgs/collectionProfile.svg";
import DefaultCover from "../utils/svgs/defaulCover.png";
import SvgIcons from "../utils/svgs/SvgIcons";
import ImageChecker from "../sharedComponents/Image";
import NftCard from "../common/Nftcard";
import { Pagination, Collapse, Popover, Select } from "antd";
import { convertNumberToHumanReadable } from "../utils/numbersConversion";
import PageNotFound from "./PageNotFound";
import Loading from "../common/loading";
import SkeletonCard from "../common/SkeletonCard";
import { Link, NavLink } from "react-router-dom";
import {
  ShoppingCartOutlined,
  SketchOutlined,
  TagOutlined,
} from "@ant-design/icons";
import VerifiedIcon from "../utils/svgs/verifiedIcon.png";
import { formatTime, timeAgo } from "../utils/defaultFunctions";

function CollectionDetailPage(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNavLinkClick = (idx) => {
    setActiveIndex(idx);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFull, setShowFull] = useState(false);
  const {
    collectionDetail,
    nfts,
    collectionloader,
    nftloader,
    success,
    totalNfts,
    activityTotalPages,
    collectionNftActivites,
    collectionNftActivitesLoading,
  } = useSelector((state) => state.collection);

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 12,
  });

  const [activityPagination, setActivityPagination] = useState({
    activityPage: 1,
    activitLimit: 12,
  });
  const [filter, setFilter] = useState("recentlyAdded");
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  const { collectionId } = useParams();
  const {
    collectionName,
    totalSupply,
    totalVolume,
    floorPrice,
    listPercentage,
    ownerAddress,
    description,
    totalOwners,
    collectionImage,
    bannerImage,
    royality,
    twitter,
    website,
    discord,
    by,
    isverified,
    floor,
  } = collectionDetail;

  useEffect(() => {
    dispatch(getCollectionById(collectionId)); //for nfts
    window.scrollTo({ top: 0, behavior: "smooth" });
    return () => {
      // Reset Collection details

      dispatch(resetCollectionDetail());
    };
  }, [collectionId]);

  useEffect(() => {
    if (collectionId) {
      dispatch(
        getCollectionDetails({
          collectionId,
          page: pagination.page,
          pageSize: pagination.pageSize,
          filter: filter,
        })
      );
    }
  }, [pagination, filter]);

  useEffect(() => {
    dispatch(
      getCollectionNftActivites({
        collectionId,
        page: activityPagination.activityPage,
        limit: activityPagination.activitLimit,
      })
    );
  }, [collectionId, activityPagination]);

  const handlePaging = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: prevPagination.page + 1,
    }));
  };
  const handleActivityPaging = () => {
    setActivityPagination((prevPagination) => ({
      ...prevPagination,
      activityPage: prevPagination.activityPage + 1,
    }));
  };

  const handleClick = (e, nftAddress) => {
    e.preventDefault();
    navigate(`${nftAddress}`, { replace: false });
  };

  const toggleDescription = () => {
    setShowFull(!showFull);
  };

  const handleChange = (value) => {
    setFilter(value);
  };

  const containerStyle = {
    maxHeight: showFull ? "none" : "100px", // Adjust the maximum height as needed
    transition: "max-height 0.5s ease", // Adjust the duration and easing as needed
  };

  const details = ["Items", "Activity"];
  if (collectionloader) {
    return (
      <div className="flex w-full h-[80vh] items-center justify-center">
        <Loading />
      </div>
    );
  }
  const renderIcons = {
    NftCreated: <TagOutlined />,
    OwnerChanged: <ShoppingCartOutlined />,
    SALE: <SketchOutlined />,
    LIST: <ShoppingCartOutlined />,
  };

  return (
    <>
      {Object.keys(collectionDetail).length > 0 ? (
        <div>
          <ImageChecker
            imageUrl={bannerImage}
            altImage={CollectionCover}
            styles=" brightness-75 w-full h-[250px] md:h-[350px] object-cover"
          />

          <div className="px-4 relative">
            <ImageChecker
              imageUrl={collectionImage}
              altImage={DefaultCover}
              styles="border-4 border-solid border-white rounded-[10px] mt-[-80px] bg-[#135B51] h-[120px] w-[120px] rounded-sm md:mt-[-120px] md:w-[180px] md:h-[180px] "
            />
            <div className="  mt-[1px]  flex justify-between items-center mr-8 ">
              <div className=" leading-normal text-xl font-semibold text-black md:text-3xl ">
                <div className="flex items-center gap-2">
                  {" "}
                  {collectionName}
                  {isverified && (
                    <img
                      src={VerifiedIcon}
                      className="w-[25px] h-[25px]"
                      alt="Verified Icon"
                    />
                  )}
                </div>
                <p className="font-semibold text-[15px] text-customColor md:[20px]">
                  By {by ? by : ""}
                </p>
              </div>
              <div className="flex gap-2 md:gap-8">
                {twitter && (
                  <span className="cursor-pointer ">
                    <a href={`${twitter}`} target="_blank">
                      <SvgIcons.TwitterSvg />
                    </a>
                  </span>
                )}

                {discord && (
                  <span className="cursor-pointer">
                    <a href={`${discord}`} target="_blank">
                      <SvgIcons.DiscordIcon />
                    </a>
                  </span>
                )}

                {website && (
                  <span className="cursor-pointer">
                    <a href={`${website}`} target="_blank">
                      <SvgIcons.WebsiteIcon />
                    </a>
                  </span>
                )}
              </div>
            </div>

            <div className="mt-[20px] flex flex-wrap gap-8 sm:gap-[50px] ">
              <div className="">
                <div className="text-[20px] font-extrabold font-mono md:text-[25px]">
                  {totalVolume
                    ? convertNumberToHumanReadable(totalVolume)
                    : "0"}
                </div>
                <div className="text-[20px]">Volume</div>
              </div>
              <div className="">
                <div className="text-[20px] font-extrabold font-mono md:text-[25px]">
                  {totalNfts ? convertNumberToHumanReadable(totalNfts) : "0"}
                </div>
                <div className="text-[20px]">Items</div>
              </div>
              <div className="">
                <div className="text-[20px] font-extrabold font-mono md:text-[25px]">
                  {floor ? convertNumberToHumanReadable(floor) : "0"}
                </div>
                <div className="text-[20px]">Floor Price</div>
              </div>
              <div className="">
                <div className="text-[20px] font-extrabold font-mono md:text-[25px]">
                  {listPercentage ? `${listPercentage.toFixed(2)}%` : "0%"}
                </div>
                <div className="text-[20px]">List</div>
              </div>
              <div className="">
                <div className="text-[20px] font-extrabold font-mono md:text-[25px]">
                  {collectionDetail?.ownersCount
                    ? collectionDetail?.ownersCount
                    : "0"}
                </div>
                <div className="text-[20px]">Owners</div>
              </div>
            </div>

            <div>
              <div className="w-[90%] h-auto md:w-[70%] break-words text-justify text-16px mt-5 mb-20 flex">
                <div style={containerStyle}>
                  {showFull ? (
                    <>
                      {description}{" "}
                      <strong
                        onClick={toggleDescription}
                        className="cursor-pointer "
                      >
                        See Less
                      </strong>
                    </>
                  ) : (
                    <>
                      {`${description?.substring(0, 210)} `}
                      <strong
                        onClick={toggleDescription}
                        className="cursor-pointer "
                      >
                        See More ...
                      </strong>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex mb-[20px]">
              <div className="flex justify-between w-[100%]">
                <div>
                  {details?.map((detail, idx) => (
                    <NavLink
                      key={idx}
                      className={`py-2 px-2 mr-[10px] text-[16px] font-semibold rounded ${
                        idx === activeIndex
                          ? " bg-slate-300"
                          : "bg-detailColor1"
                      } md:px-8 md:mr-[10px]`}
                      onClick={() => handleNavLinkClick(idx)}
                    >
                      {detail}
                    </NavLink>
                  ))}
                </div>

                {/**here add dropdown for filters */}

                {activeIndex === 0 && (
                  <Select
                    value={filter}
                    style={{ width: 200 }}
                    onChange={handleChange}
                    options={[
                      { value: "recentlyAdded", label: "Recently Added" },
                      { value: "priceLowToHigh", label: "Price low to high" },
                      { value: "priceHighToLow", label: "Price high to low" },
                      { value: "sortByRank", label: "Sort by rank" },
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-100 h-[1px] bg-black mb-[10px]"></div>
          {activeIndex === 0 && (
            <>
              {nftloader && nfts?.length < 1 ? (
                <>
                  <div className="px-4 max-w-[1500px] grid grid-cols-2 mx-auto lg:grid-cols-4 md:grid-cols-3 gap-4 mx-4 mb-4">
                    {Array(12)
                      .fill(true)
                      .map((item, index) => (
                        <SkeletonCard key={index} />
                      ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 max-w-[1500px] grid grid-cols-2 mx-auto lg:grid-cols-4 md:grid-cols-3 gap-4 mx-4 mb-4">
                    {nfts?.length > 0 &&
                      nfts?.map((el, index) => (
                        <>
                          <NftCard
                            nft={el}
                            handleClick={(e) =>
                              handleClick(e, el.nftContractAddress)
                            }
                            key={index}
                          />
                        </>
                      ))}
                    {nfts?.length === 0 && (
                      <div className="flex items-center justify-center">
                        No items found!{" "}
                      </div>
                    )}
                  </div>
                  {nftloader && nfts?.length > 0 && (
                    <>
                      <div className="px-4 max-w-[1500px] grid grid-cols-2 mx-auto lg:grid-cols-4 md:grid-cols-3 gap-4 mx-4 mb-4">
                        {Array(12)
                          .fill(true)
                          .map((item, index) => (
                            <SkeletonCard key={index} />
                          ))}
                      </div>
                    </>
                  )}
                  {totalNfts > nfts?.length && (
                    <>
                      <div className="m-[auto] flex items-center justify-center p-4">
                        <button
                          className=" text-bold text-[16px] cursor-pointer gap-2 bg-[#F4F4F4] w-[200px] h-[40px] rounded-[40px] hover:bg-[#e0e0e0]"
                          onClick={handlePaging}
                          disabled={totalNfts === (nfts ? nfts.length - 1 : 0)}
                        >
                          {nftloader ? "Loading" : "See More"}
                        </button>
                      </div>

                      {/* <div className="flex justify-around m-[0 2em] mb-[2em]">
                        <Pagination
                          total={totalNfts}
                          current={pagination.page}
                          pageSize={pagination.pageSize}
                          defaultPageSize={100}
                          showSizeChanger
                          onChange={handlePaging}
                        />
                      </div> */}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {activeIndex === 1 && (
            <div className="w-[100%] overflow-x-scroll ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      From
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      To
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {collectionNftActivites?.map((item) => (
                    <tr key={item._id}>
                      <td className="px-6 py-4 whitespace-nowrap  font-bold">
                        <div className="flex gap-2">
                          <span>{renderIcons[item.event]}</span>
                          <span>{item.event}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  font-bold">
                        {item.price ? item.price : "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[#2081E2] font-bold">
                        {/* {trimString(item?.from.toString())} */}
                        {item._from !==
                        "0x0000000000000000000000000000000000000000000000000000000000000000" ? (
                          <a
                            href={`https://venomscan.com/accounts/${item._from}`}
                            target="_blank"
                          >
                            {item.from}
                          </a>
                        ) : (
                          <span>{item.from}</span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[#2081E2]  font-bold">
                        <a
                          href={`https://venomscan.com/accounts/${item._to}`}
                          target="_blank"
                        >
                          {item.to}
                        </a>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  font-bold">
                        <Popover content={formatTime(item.date)}>
                          {timeAgo(item.date)}
                        </Popover>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {activityTotalPages !== activityPagination.activityPage && (
                <div className="m-[auto] flex items-center justify-center p-4">
                  <button
                    className=" text-bold text-[16px] cursor-pointer gap-2 bg-[#F4F4F4] w-[200px] h-[40px] rounded-[40px] hover:bg-[#e0e0e0]"
                    onClick={handleActivityPaging}
                    disabled={collectionNftActivitesLoading}
                    // disabled={totalNfts === (nfts ? nfts.length - 1 : 0)}
                  >
                    {collectionNftActivitesLoading ? "Loading..." : "See More"}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  );
}

export default CollectionDetailPage;
