import React, { useEffect, useState } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import {
  GetUserById,
  GetUserOwnedNfts,
  getUserActivity,
} from "../../redux/reducers/user";
import NftCard from "../../common/Nftcard";
import ImageChecker from "../../sharedComponents/Image";
import Loading from "../../common/loading";
import SkeletonCard from "../../common/SkeletonCard";
import DropCardSkeleton from "../../common/DropCardSkeleton";
import UserActivity from "./UserActivity";
import { formatAddress } from "../../utils/formatAddress";

function UserProfile() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const navigate = useNavigate();
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const {
    address,
    user,
    nfts,
    loader,
    ownnftloader,
    totalCount,
    userActivity,
  } = useSelector((state) => state.user);
  const { venomProvider } = useSelector((state) => state.venom);

  const details = ["Collected", "Offers made", "Activity"];

  useEffect(() => {
    dispatch(getUserActivity(profileId));
  }, [profileId]);

  const handleNavLinkClick = (idx) => {
    setActiveIndex(idx);
  };

  const handlePaging = (page, size) => {
    setPagination({ page, pageSize: size });
  };

  useEffect(() => {
    dispatch(GetUserById(profileId));
  }, [profileId]);

  useEffect(() => {
    dispatch(
      GetUserOwnedNfts({
        page: pagination.page,
        pageSize: pagination.pageSize,
        walletAddress: profileId,
      })
    );
  }, [profileId, pagination.page, pagination.pageSize, dispatch]);


  if (loader) {
    return (
      <div className="flex w-full h-[80vh] items-center justify-center ">
        <Loading />
      </div>
    );
  }
  console.log(profileId, "nfts");
  return (
    <>
      <div className="">
        <div id="collectionDetail">
          {/* Background Picture */}
          <div className="w-full h-[35vh] object-cover relative bg-[#aeaeae]">
            <ImageChecker
              imageUrl={user?.backgroudPicture}
              styles="brightness-75 w-full h-[35vh] object-cover relative"
            />
            {profileId === address && (
              <button
                className="float-right absolute bottom-3 right-12 bg-[#292929] border-2 border-[#3e3e3e] rounded-lg text-white px-4 py-2 text-base hover:border-[#fff] cursor-pointer transition"
                onClick={() => navigate(`/${profileId}/settings`)}
              >
                Edit
              </button>
            )}
          </div>

          {/* Profile Picture */}
          <div className="px-[1em] mt-[1em]">
            <div className=" bg-[black] w-[168px] h-[168px] mt-[-10em] absolute rounded-full">
              <ImageChecker
                imageUrl={user?.profilePicture}
                styles="bg-[#aeaeae] w-[168px] h-[168px] rounded-full"
              />
            </div>
            <div className="mt-10">
              <p className="text-[31px] font-bold">
                {user.userName || "Unnamed"}
              </p>
              <div className="flex gap-10">
                <p className="font-[16px] font-thin">
                  {user?.walletAddress
                    ? `${formatAddress(user.walletAddress)}`
                    : "Wallet address"}
                </p>
                {user.date &&
                  `Join ${new Date(user.date).toLocaleString("default", {
                    year: "numeric",
                    month: "long",
                  })}`}
              </div>
            </div>
            <div className="mex-w-sm mt-4 md:max-w-md ">
              {user?.ShortBio || ""}
            </div>

            {/* Navigation Links */}
            <div className="flex mb-[32px] mt-10">
              {details.map((detail, idx) => (
                <NavLink
                  key={idx}
                  className={`py-2 px-2 mr-[10px] text-[16px] font-semibold rounded ${
                    idx === activeIndex ? " bg-slate-300" : "bg-detailColor1"
                  } md:px-8 md:mr-[30px]`}
                  onClick={() => handleNavLinkClick(idx)}
                >
                  {detail}
                </NavLink>
              ))}
            </div>
          </div>

          {/* Separator */}
          <div className="w-100 h-[2px] bg-gray-300 mb-[30px]"></div>

          {/* Content Based on Active Index */}
          {activeIndex === 0 && (
            <>
              {ownnftloader ? (
                <div className="px-4 max-w-[1500px] grid grid-cols-2 mx-auto lg:grid-cols-4 md:grid-cols-3 gap-4 mx-4 mb-4">
                  {Array(10)
                    .fill(true)
                    .map((_, index) => (
                      <SkeletonCard key={index} />
                    ))}
                </div>
              ) : (
                <>
                  {nfts?.length > 0 ? (
                    <div className=" px-4 max-w-[1500px] grid grid-cols-2 mx-auto lg:grid-cols-4 md:grid-cols-3 gap-4 mx-4 mb-4">
                      {nfts.map((nftItem) => (
                        <NftCard
                          key={nftItem.id}
                          nft={nftItem}
                          handleClick={() =>
                            navigate(
                              `/collections/${nftItem?.collectionAddress}/${nftItem?.nftContractAddress}`
                            )
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center w-full h-[356px] mb-[40px] pb-[8em]">
                      <div className="text-[30px] font-bold">
                        No items found for this search
                      </div>
                      <div
                        className="cursor-pointer flex justify-center items-center w-[281px] h-[61px] rounded-[100px] bg-[#1D6C64] text-white mt-[1em]"
                        onClick={() => navigate("/")}
                      >
                        Back to Home
                      </div>
                    </div>
                  )}
                </>
              )}

              {nfts?.length > 0 && (
                <div className="flex justify-end mr-4 lg:mr-10 lg:m-[0 2em] mb-[2em]">
                  <Pagination
                    total={totalCount}
                    size="large"
                    current={pagination.page}
                    pageSize={pagination.pageSize}
                    defaultPageSize={100}
                    showSizeChanger
                    onChange={handlePaging}
                  />
                </div>
              )}
            </>
          )}
          {activeIndex === 1 && (
            <div className="flex justify-center items-center my-24 text-3xl">
              <h1>Coming Soon...</h1>
            </div>
          )}
          {activeIndex === 2 && <UserActivity data={userActivity} />}
        </div>
      </div>
    </>
  );
}

export default UserProfile;
