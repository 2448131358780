import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedCollection } from "../redux/reducers/collection";
import landingBg from '../utils/svgs/LangingBG.svg'
import FeatureSlider from "../common/featureSlider";


function Featured({ setActiveTab, activeTab, categories, categoryName }) {
  const dispatch = useDispatch();
  const { venom } = useSelector((state) => state);
  const [background, setBackground] = useState(0);

  const handleTabChange = (el) => {
    setActiveTab(el);
  };
  const {
    featuredSuccess,
    featuredCollections,
    featuredloading,
    featuredError,
  } = useSelector((state) => state.collection);

  useEffect(() => {
    dispatch(getFeaturedCollection(categoryName));
  }, [dispatch, categoryName]);

  return (
    <>
      <div
        className="absolute inset-0 z-0 bg-cover bg-top bg-no-repeat transition-[background] duration-500 after:absolute after:inset-0 after:z-10 after:backdrop-blur-xl after:[background:linear-gradient(0deg,rgb(255,255,255)_5%,rgba(0,0,0,0)_60%)_rgba(0,0,0,0.5)]"
        style={{
          backgroundImage: `url(${featuredCollections[background]?.collectionImage})`,
          backgroundImage: `url(${landingBg})`,
          maxHeight:"80vh"
        }}
      ></div>
      <div className="relative text-white z-100 pl-[2em]">
        <div className="container flex z-100">
          <ul className="flex space-x-6 mt-[1em]">
            {categories?.length > 0 &&
              categories.map((el, i) => (
                <li
                  key={i}
                  className={`p-2 text-[16px] font-bold rounded-lg ${
                    el === activeTab && "bg-whiteOpacity"
                  }`}
                  onClick={(e) => handleTabChange(el)}
                >
                  <a className="hover:text-white-10">
                    {el === "" ? "Explore" : el}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="mx-auto max-w-8xl px-4 md:px-6 lg:px-8 2xl:px-14 z-100" >
        <FeatureSlider changeBackground={setBackground} />
      </div>
    </>
  );
}

export default Featured;
