import { configureStore } from "@reduxjs/toolkit";
import venomReducer from "./reducers/venom";
import userReducer from "./reducers/user";
import collectionSlice from "./reducers/collection";
import CategoriesSlice from "./reducers/categories";
import RankingSlice from "./reducers/rankings";
import privacypolicySlice from "./reducers/privacypolicy";
import dropSlice from "./reducers/drop";
import pendingTransactionSlice from "./reducers/pendingTransactions";
import nftOwnerSlice from "./reducers/nftDetails";
export default configureStore({
  reducer: {
    user: userReducer,
    venom: venomReducer,
    collection: collectionSlice,
    categories: CategoriesSlice,
    rankings: RankingSlice,
    privacypolicy: privacypolicySlice,
    drops: dropSlice,
    pendingTransaction: pendingTransactionSlice,
    nftOwnerAndManager: nftOwnerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});
