import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import twitterIcon from "../utils/svgs/twitter.png";
import PoweredIcon from "../utils/svgs/poweredIcon.png";
import EmailLogo from "../utils/svgs/EmailLogo.png";
import DiscordLogo from "../utils/svgs/DiscordLogo.png";
import { Link } from "react-router-dom";
import { API } from "../utils/service";
import { useNavigate } from "react-router-dom";
import { scrollTop } from "../utils/commonFunctions";
function Footer(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleEmail = async () => {
    try {
      const response = await API.post("/subscribers", {
        email: email,
      });
      if (response.data.success === true) {
        console.log(response, "success");
        setEmail("");
        return toast.success(response.data?.message);
      }
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };
  return (
    <footer className="bg-[#032032] px-4 py-4">
      <div className="  flex flex-col gap-[1em]  w-full md:w-full lg:w-full  md:grid  lg:h-[322px] px-[1%] pb-[5%] pt-[3%]  md:grid-cols-[1fr] lg:grid-cols-[2fr,1fr,1fr]">
        {/* First Inner Div (Takes More Area) */}
        <div className="flex flex-col justify-items-center	">
          <div className="text-white text-2xl font-bold">Venom Marketplace</div>
          <div className=" mt-3 text-white text-sm font-light">
            An NFT marketplace on the Venom Foundation blockchain, empowering
            creators and collectors. Join us to shape the NFT future with
            innovation and creativity.
          </div>
        </div>

        {/* Second Inner Div */}
        <div className="flex justify-between gap-[2em] md:gap-[5em] lg:gap-[5em] xl:gap-[5em]  md:justify-center lg:justify-between">
          {" "}
          <div className="text-white ">
            <ul>
              <li className="pb-[1em] font-bold text-base bg-gradient-to-r text-transparent bg-clip-text from-[#73E0A9] to-[#5B68DF]">
                Company
              </li>
              {/* <li className="pb-[1em] cursor-pointer text-xs font-bold">
                About
              </li> */}
              {/* <li className="pb-[1em] cursor-pointer text-xs font-bold">
                Career
              </li>
              <li className="pb-[1em] cursor-pointer text-xs font-bold">
                Links
              </li> */}

              <li
                className="pb-[1em] cursor-pointer text-xs "
                onClick={() => {
                  navigate("/community_standard");
                  scrollTop();
                }}
              >
                Community Standard
              </li>

              <li className="pb-[1em] cursor-pointer text-xs">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfVM--zr85IS5TIMjYYNxRP6bB8wRcfCwtuJcGnfvAOllu04g/viewform"
                  target="_blank"
                >
                  {" "}
                  List Your Collection
                </a>
              </li>

              {/* <li className="pb-[1em]">Ventures</li> */}
              {/* <li className="pb-[1em]">Grants</li> */}
            </ul>
          </div>
          <div className="text-white">
            <ul>
              <li className="pb-[1em] font-bold text-base bg-gradient-to-r text-transparent bg-clip-text from-[#73E0A9] to-[#5B68DF]">
                Resource
              </li>
              <li className="pb-[1em] cursor-pointer text-xs font-bold">
                All NFTs
              </li>
              {/* <li className="pb-[1em]">Gas free marketplace</li> */}
              <li className="pb-[1em] cursor-pointer text-xs font-bold">Art</li>
              <li className="pb-[1em] cursor-pointer text-xs font-bold">
                Gaming
              </li>
              <li className="pb-[1em] cursor-pointer text-xs font-bold">
                PFPs
              </li>
            </ul>
          </div>
          <div className="text-white ">
            <ul>
              <li className="pb-[1em] font-bold text-base bg-gradient-to-r text-transparent bg-clip-text from-[#73E0A9] to-[#5B68DF]">
                Stats
              </li>
              <li className="pb-[1em] cursor-pointer text-xs font-bold"   onClick={() => {
                  navigate("/stats");
                  scrollTop();
                }}>
                Ranking
              </li>
              <li className="pb-[1em] cursor-pointer text-xs font-bold">
                Activity
              </li>
            </ul>
          </div>
        </div>

        {/* Third Inner Div */}
        <div className=" flex flex-col text-white gap-[2em]">
          <div className="flex flex-row justify-center  md:flex-row lg:flex-row gap-[1em] lg:justify-end mt-2 md:justify-center  ">
            <div className="rounded-[12px] border border-gray-300 w-auto h-[42px] min-w-[100px]">
              <input
                placeholder="Subscription"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                className="rounded-[12px] px-[1em] w-full h-full border border-gray-300  py-1 text-black focus:outline-blue focus:border-blue-400"
              />
            </div>

            <button
              className="w-[135px] h-[42px]   text-white-200  p-2 rounded-[12px] bg-[#1D6C64] "
              onClick={handleEmail}
            >
              Subscribe
            </button>
          </div>
          <div className="flex flex-col items-end md:flex-col md:justify-center mb-8  lg:flex-col lg:justify-end">
            <div className=" pl-[2em] pr-[2em] font-bold text-base bg-gradient-to-r text-transparent bg-clip-text from-[#73E0A9] to-[#5B68DF]">
              Follow Us on
            </div>
            <div className="flex justify-items-end items-end">
              <div className=" mt-[1em] flex pl-[2em] gap-4">
                <div className="border flex justify-center items-center rounded-[8px] w-[45px] h-[45px]">
                  <a href="https://discord.gg/venompunks" target="_blank">
                    <img src={DiscordLogo} />
                  </a>
                </div>
                <div className="border flex justify-center items-center rounded-[8px] w-[45px] h-[45px]">
                  <a href="https://twitter.com/venomsea_" target="_blank">
                    <img src={twitterIcon} />
                  </a>
                </div>
                <div className="border flex justify-center items-center rounded-[8px] w-[45px] h-[45px]">
                  <a href="mailto:askvenomsea@gmail.com">
                    <img src={EmailLogo} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex text-white text-xs justify-between px-[1em] pb-[1em]">
        <div>Copyright &copy; 2024 VenomSea</div>
        <a href="https://venompunks.com/" target="_blank">
          <div className="flex items-center gap-1 cursor-pointer">
            {" "}
            <img src={PoweredIcon} className="w-[12px] h-[12px]" />
            <span>Powered By Venom Punks</span>
          </div>
        </a>
        <div className="flex gap-5 cursor-pointer">
          <Link to={"privacy_policy"}>
            <div>Privacy Policy</div>
          </Link>{" "}
          <Link to={"terms_of_services"}>
            <div>Term Of Services</div>
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
