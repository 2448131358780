import React from 'react';

const CollectionTable = ({ collections, onRowClick }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Collection Name</th>
          <th>Description</th>
          <th>Owner Address</th>
          <th>Total Supply</th>
          <th>Royalty</th>
        </tr>
      </thead>
      <tbody>
        {collections.map((collection) => (
          <tr
            key={collection.collectionAddress}
            onClick={() => onRowClick(collection.collectionAddress)}
          >
            <td>{collection.collectionName}</td>
            <td>{collection.description}</td>
            <td>{collection.ownerAddress}</td>
            <td>{collection.totalSupply}</td>
            <td>{collection.royality}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CollectionTable;
