import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Address } from 'everscale-inpage-provider';
import { privacypolicySlice, setModel } from '../redux/reducers/privacypolicy';
import { setPrivacyLoader, setPrivacyPolicy } from '../redux/reducers/user';
import { LoadingOutlined } from '@ant-design/icons';
import { signString } from '../utils/contentData';
// import { SubmitWallet } from "../redux/reducers/user";

const PrivacypolicyPopup = () => {
  const [agreement, setAgreement] = useState(false);

  const handleChange = (event) => {
    setAgreement(event.target.checked);
  };

  const dispatch = useDispatch();
  const { venomInstance, venomProvider, standaloneProvider, walletError } =
    useSelector((state) => state.venom);
  const [discordInfo, setDiscordInfo] = useState({});
  const { popup } = useSelector((state) => state.privacypolicy);
  const { address, errorMsg, discordRole, privacyLoader } = useSelector(
    (state) => state.user
  );
  const submitWallet = async () => {
    console.log('submitting wallet');
    dispatch(setPrivacyLoader(true));
    console.log(new Address(address));
    const providerState = await venomProvider?.getProviderState?.();
    console.log(providerState?.permissions);
    const publicKey = providerState?.permissions.accountInteraction?.publicKey;
    console.log(publicKey);
    // dispatch(setModel(false));
    try {
      const base64 = btoa(
        JSON.stringify({
          walletAddress: address,
          message: signString,
        })
      );
      console.log(base64);
      const sign = await venomProvider.signDataRaw({
        data: base64,
        publicKey,
      });
      console.log(sign);
      //dispatch privacy sign wallet here
      dispatch(
        setPrivacyPolicy({
          publicKey,
          base64,
          signature: sign.signature,
          walletAddress: address,
        })
      );
      // dispatch(SubmitWallet({ publicKey, base64, signature: sign.signature }));
    } catch (errr) {
      console.log(errr);
    }
    // venomProvider.sign('ddd');
    // console.log(standaloneProvider)
  };

  return (
    <>
      {popup ? (
        <>
          <div className= " justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl ">
              {/*content*/}
              <div className="  border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Terms of Service</h3>

                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => dispatch(setModel(false))}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-4 flex-auto h-40 overflow-auto mt-0">
                  <div className="p-2 space-y-6 mt-0">
                    <p className="font-semibold	mt-0">
                      Last Updated: September 3, 2023
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      <span className="font-semibold	">1. Introduction</span>
                      <br />
                      Welcome to VenomSea ("we," "us," "our"). At VenomSea, we
                      value your privacy and are committed to protecting your
                      personal information. This Privacy Policy explains how we
                      collect, use, disclose, and safeguard your data when you
                      use our services.
                    </p>

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      <span className="font-semibold	">
                        2. Information We Collect
                      </span>
                      <br />
                      2.1.User-Provided Information We may
                      <br />
                      collect personal information you provide to us when you
                      create an account, make transactions, or contact our
                      support team. This information may include your name,
                      email address, wallet address, and other details.
                      <br />
                      2.2. Automatically Collected <br />
                      Information We may collect certain information
                      automatically when you use our platform, such as IP
                      addresses, device information, and browsing activity. We
                      may use cookies and similar technologies for this purpose.
                      <span className="font-semibold	">
                        3.How We Use Your Information
                      </span>
                      <br />
                      3.1. To Provide Services We use your information to offer,
                      maintain, and improve our NFT marketplace services,
                      including processing transactions and providing customer
                      support. <br />
                      3.2. To Communicate We may contact you regarding your
                      account, transactions, updates, and promotional offers.
                      <br />
                      3.3. To Enhance Security We use your data to monitor and
                      enhance the security of our platform, detect and prevent
                      fraud, and comply with legal obligations. <br />
                      <span className="font-semibold	">
                        4. Information Sharing
                      </span>
                      <br />
                      We do not sell, rent, or trade your personal information
                      to third parties without your consent. However, we may
                      share your data in the following circumstances: <br />
                      4.1. With Service Providers We may share information with
                      trusted service providers who assist us in providing and
                      maintaining our services. 4.2. Legal Compliance <br />
                      We may disclose your information to comply with legal
                      requirements, enforce our policies, or protect our rights,
                      privacy, safety, or property. <br />
                      <span className="font-semibold	">
                        5. Your Choices You have the right to:
                      </span>
                      <br />
                      5.1. Access, Update, or Delete Data You may access,
                      update, or delete your personal information by logging
                      into your account or contacting us. <br />
                      5.2. Opt-Out You may opt-out of receiving promotional
                      communications from us by following the unsubscribe
                      instructions provided in emails.
                      <span className="font-semibold	">6. Data Security</span>
                      <br />
                      We take reasonable measures to protect your data from
                      unauthorized access or disclosure. However, please be
                      aware that no data transmission over the internet or
                      storage method is entirely secure. <br />
                      <span className="font-semibold	">
                        7. Changes to this Privacy Policy
                      </span>
                      <br />
                      We may update this Privacy Policy periodically to reflect
                      changes in our practices or legal requirements. The latest
                      version will be posted on our website. <br />
                      <span className="font-semibold	">
                        8. Changes to This Privacy Policy
                      </span>
                      <br />
                      This Privacy Policy may be updated from time to time for
                      any reason. We will notify you of any changes to our
                      Privacy Policy by posting the new Privacy Policy at
                      https://venomsea.io/privacy. The date the Privacy Policy
                      was last revised is identified at the beginning of this
                      Privacy Policy. You are responsible for periodically
                      visiting our Service and this Privacy Policy to check for
                      any changes. <br />
                      <span className="font-semibold	">9. Contact Us</span>
                      <br />
                      If you have any questions, concerns, or requests regarding
                      this Privacy Policy or your data, please contact us at
                      askvenomsea@gmail.com.
                    </p>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-2 pr-4 border-t border-solid border-blueGray-200 rounded-b">
                  <div className="flex items-center mb-4 ml-4">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      name="agreement"
                      onChange={handleChange}
                      value=""
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="agreement"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      <span>Accept Terms & Conditions</span>
                    </label>
                  </div>
                  <div>
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => dispatch(setModel(false))}
                    >
                      Decline
                    </button>
                    <button
                      className={
                        !agreement
                          ? " bg-gray-400 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow mr-1 mb-1 cursor-not-allowed"
                          : "bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      type="button"
                      disabled={privacyLoader || !agreement}
                      onClick={submitWallet}
                    >
                      <div className="flex gap-2">
                        {privacyLoader && <LoadingOutlined />}
                        <span> I accept</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default PrivacypolicyPopup;
