import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import HomePage from "./pages/HomePage";
import DropsListPage from "./pages/Drops/DropsListPage";
import DropDetailPage from "./pages/Drops/DropDetailPage";
import CollectionListPage from "./pages/CollectionListPage";
import CollectionDetailPage from "./pages/CollectionDetailPage";
import NFTDetailPage from "./pages/NFTDetail/index";
import UserProfile from "./pages/Profile/UserProfile";
import {
  terms_Of_Services,
  privacy_policy,
  community_standard,
} from "./utils/contentData";
import store from "./redux/store";
import PageNotFound from "./pages/PageNotFound";
import "./index.css";
import ContentPage from "./pages/ContentPage";
import UserProfileUpdate from "./pages/Profile/UserProfileUpdate";
import Stats from "./pages/Stats";

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "*",

        element: <HomePage />,
      },
      {
        path: "collections",
        element: <CollectionListPage />,
      },
      {
        path: "privacy_policy",
        element: (
          <ContentPage paragraph={privacy_policy} heading={"Privacy Policy"} />
        ),
      },
      {
        path: "terms_of_services",
        element: (
          <ContentPage
            paragraph={terms_Of_Services}
            heading={"Terms of services"}
          />
        ),
      },
      {
        path: "community_standard",
        element: (
          <ContentPage
            paragraph={community_standard}
            heading={"Community Standards"}
          />
        ),
      },
      // {
      //   path: "drops",
      //   element: <DropsListPage />,
      // },
      // {
      //   path: "drops/:dropId",
      //   element: <DropDetailPage />,
      // },
          {
        path: "stats",
        element: <Stats />,
      },
      {
        path: "collections/:collectionId",
        element: <CollectionDetailPage />,
      },
      {
        path: "collections/:collectionId/:nftAddress",
        element: <NFTDetailPage />,
      },
      {
        path: ":profileId",
        element: <UserProfile />,
      },
      {
        path: ":profileId/settings",
        element: <UserProfileUpdate />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
   
      <RouterProvider router={router} />
    
  </Provider>
  // </React.StrictMode>,
);
