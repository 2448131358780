import React, { useCallback, useEffect, useState, useRef } from "react";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import { API } from "../../utils/service";
import { debounce } from "lodash";

const SearchComponent = ({ scrolling }) => {
  const [results, setResults] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        console.log("Updating state");
        setResults([]);
        setSearch("");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); //

  const handleSearch = (val) => {
    setSearch(val);
  };

  const searchFunc = useCallback(
    debounce(async (searchText) => {
      setLoading(true);
  
      try {
        const encodedSearchText = encodeURIComponent(searchText); // Encoding the search text
        const res = await API.get(`/search?search=${encodedSearchText}`);
        if (res.data.result) {
          setResults(res.data.result);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }, 300),
    []
  );
  

  useEffect(() => {
    if (search.length > 2) {
      searchFunc(search);
    }
    if (search.length === 0) {
      setResults([]);
    }
  }, [search]);

  const handleReset = () => {
    setResults([]);
    setSearch("");
    setLoading(false);
  };


  return (
    <div ref={componentRef}>
      <SearchBar
        onSearch={handleSearch}
        handleReset={handleReset}
        searchText={search}
        scrolling={scrolling}
      />
      {/** TODO: search result UI will be set */}
      <SearchResults
        results={results}
        searchLoading={loading}
        searchText={search}
        // onClickListItem={onClickListItem}
        handleReset={handleReset}
      />
    </div>
  );
};

export default SearchComponent;
