import React from "react";
import { useNavigate } from "react-router";
import defaulCover from "../utils/svgs/defaulCover.png";
import ImageChecker from "../sharedComponents/Image";
import VerifiedIcon from "../utils/svgs/verifiedIcon.png";
import { convertNumberToHumanReadable } from "../utils/numbersConversion";
import venomIcon from "../utils/svgs/v.svg";
//This res-usable component is for CollectionCard.
const Card = ({ collection }) => {
  const navigate = useNavigate();
  console.log(collection, "card data");
  return (
    <>
      <div
        className="cursor-pointer bg-white border border-gray-200 rounded-lg shadow flex-shrink-0 m-2"
        onClick={() => navigate(`/collections/${collection.collectionAddress}`)}
      >
        <ImageChecker
          imageUrl={collection.collectionImage}
          altImage={defaulCover}
          styles="rounded-t-lg  hover:scale-105 duration-500"
        />
        <div className="flex flex-col justify-between p-2">
          <div className="flex items-center gap-1">
            <span className="font-bold text-[14px] truncate">
              {collection?.collectionName}
            </span>
            {collection?.isverified && (
              <img
                src={VerifiedIcon}
                className="w-[19px] h-[19px]"
                alt="Verified Icon"
              />
            )}
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="text-[#707A83] text-[12.74px] font-bold">
                Floor
              </span>
              <span className="text-[14px] font-bold flex items-center gap-[5px]">
                {collection.floorPrice
                  ? convertNumberToHumanReadable(collection.floorPrice)
                  : "0 V"}
                <img
                  src={venomIcon}
                  style={{ height: "12px", width: "12px" }}
                  alt="V"
                />
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-[#707A83] text-[12.74px] font-bold">
                Volume
              </span>
              <span className="text-[14px] font-bold flex items-center gap-[5px]">
                {collection?.totalVolume
                  ? convertNumberToHumanReadable(collection?.totalVolume)
                  : "0.0V"}

<img
                  src={venomIcon}
                  style={{ height: "12px", width: "12px" }}
                  alt="V"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
