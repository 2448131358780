import React, { useState } from 'react';
import DefaultCover from '../../utils/svgs/defaulCover.png';

const ImageChecker = ({ imageUrl, altImage, styles = '', divStyle = '' }) => {
  const [isValidImage, setIsValidImage] = useState(true);

  const handleImageError = () => {
    console.log('on error');
    setIsValidImage(false);
  };

  console.log(isValidImage, 'isValidImage', imageUrl);

  return (
    <div className={divStyle}>
      {/* <img
        src={imageUrl ? imageUrl : altImage}
        alt='Image'
        onError={handleImageError}
        className={styles}
      /> */}
      {isValidImage ? (
        <img
          src={imageUrl ? imageUrl : altImage}
          alt=""
          onError={handleImageError}
          className={styles}
        />
      ) : (
        <img
          src={DefaultCover}
          alt="Image"
          className={styles}
          style={{ objectFit: 'cover' }}
        />
      )}
    </div>
  );
};

export default ImageChecker;
