import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Address } from "everscale-inpage-provider";
import { Collapse } from "antd";
import { MenuOutlined, CaretDownOutlined } from "@ant-design/icons";
import { BigNumber } from "bignumber.js";

import NftDetailIcon from "../../utils/svgs/profile-icon.png";
import ShoppingCartIcon from "../../utils/svgs/shopping_cart.png";

import NFTABI from "../../abi/Nft.abi.json";
import DIRECTSELLABI from "../../abi/DirectSell.abi.json";
import FactoryDSABI from "../../abi/FactoryDirectSell.abi.json";
import VaultABI from "../../abi/VaultABI.abi.json";
import ListingModal from "./ListingModal";

import NFTListings from "./NFTListings";
import { API } from "../../utils/service";
import NFTPriceHistory from "./NFTPriceHistory";
import { getOwnerAndManager } from "../../redux/reducers/nftDetails";
import VerifiedIcon from "../../utils/svgs/verifiedIcon.png";
import { formatAddress } from "../../utils/formatAddress";
import { environment } from "../../environment.config";
import toast from "react-hot-toast";
import { callTransactionStatus } from "../../utils/blockchainFunctions";
import { setVenomProvider } from "../../redux/reducers/venom";
BigNumber.config({ EXPONENTIAL_AT: 257 });

const genExtra = () => (
  <CaretDownOutlined
    onClick={(event) => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    }}
  />
);

function NFTActionArea(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isListLoading, setlsListLoading] = useState(false);
  const [isDeListLoading, setIsDelistLoading] = useState(false);
  const [isBuyLoading, setIsBuyLoading] = useState(false);

  const { nft, moreFromThisCollection, nftActivity } = useSelector(
    (state) => state.collection
  );
  const {
    nft_Owner_Manager,
    isOwnerFetchLoading,
    isOwnerFetchSuccess,
    isFetchOwnerError,
  } = useSelector((state) => state.nftOwnerAndManager);
  const { address, errorMsg } = useSelector((state) => state.user);
  const { venomInstance, venomProvider } = useSelector((state) => state.venom);
  const { collectionId, nftAddress } = useParams();
  const [open, setOpen] = useState(false);

  const onLogin = async () => {
    if (!venomInstance) return;
    await venomInstance.connect();
  };

  const priceHistory = [
    {
      key: "1",
      label: <p className="font-bold text-md">Price History</p>,
      children: <NFTPriceHistory />,
      extra: genExtra(),
    },
    {
      key: "2",
      label: <p className="font-bold text-md">Listing</p>,
      children: <NFTListings />,
      extra: genExtra(),
    },
    {
      key: "3",
      label: <p className="font-bold text-md">Offers</p>,
      children: <p>{""}</p>,
      extra: genExtra(),
    },
  ];
  // calculate gas value
  const calcValue = (gas, gasK) => {
    const gasPrice = new BigNumber(1).shiftedBy(9).div(gasK);
    return new BigNumber(gas.dynamicGas)
      .times(gasPrice)
      .plus(gas.fixedValue)
      .toNumber();
  };

  const handleList = async (price) => {
    try {
      if (!address) {
        return onLogin();
      }
      setlsListLoading(true);
      console.log("_________Listing Item for Sale start_______");
      // Address for Nft  Contract - read params from url.
      const nftContractAddressObject = new Address(nftAddress);
      console.log(collectionId);
      const nftInstance = new venomProvider.Contract(
        NFTABI,
        nftContractAddressObject
      );
      // Address of connected wallet to the applciation
      const connectedUser = new Address(address);
      // Create Instance of FactoryDirectSell.sol
      const factoryDSInstance = new venomProvider.Contract(
        FactoryDSABI,
        new Address(environment.factoryDirectSell)
      );
      // get gas value for listing
      const gasValue = (await factoryDSInstance.methods.getGasValue().call())
        .value0;
      console.log("--------Before creating payload---------------");
      // call buildDirectSellCreationPayload pure function to get payload for change manager

      let payload = (
        await factoryDSInstance.methods
          .buildDirectSellCreationPayload({
            callbackId: 0,
            _startTime: Math.round(Date.now() / 1000),
            durationTime: 0,
            _paymentToken: new Address(environment.paymentTokenAddress), // wever root address TODO: needs to be read from env
            _price: Number(price) * 1000000000,
            recipient: connectedUser,
            discountCollection: new Address(
              "0:0000000000000000000000000000000000000000000000000000000000000000"
            ),
            discountNftId: 0,
          })
          .call()
      ).value0;

      console.log("after creating payload---------------");
      console.log(gasValue);
      const _val = calcValue(gasValue.sell, gasValue.gasK).toString();
      console.log(_val);
      const callbacks = [
        [
          new Address(environment.factoryDirectSell),
          {
            value: _val,
            payload: payload,
          },
        ],
      ];
      // new Manager should be the FactoryDirectSell. >> This FactoryDeirectsell.sol inturns makes the Directsell.sol as manager
      const newManager = environment.factoryDirectSell;
      const changeManagerTx = await nftInstance.methods
        .changeManager({
          newManager: newManager,
          sendGasTo: connectedUser,
          callbacks,
        })
        .send({
          from: connectedUser,
          amount: (
            calcValue(gasValue.sell, gasValue.gasK) + 100000000
          ).toString(),
        });

      if (changeManagerTx) {
        const status = await callTransactionStatus(
          changeManagerTx?.outMessages[0].hash
        );
        if (status) {
          setlsListLoading(false);
          setOpen(false);
          toast.success("Listed Successfully");
          dispatch(
            getOwnerAndManager({
              collectionAddress: nft?.collectionAddress,
              NftAddress: nft?.nftContractAddress,
            })
          );
        } else {
          setlsListLoading(false);
          toast.error("Something went wroung try again");
        }
      }
      //
      // handle state for showing
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setlsListLoading(false);
      toast.error(message);
    }
  };

  const handleDeList = async () => {
    try {
      if (!address) {
        return onLogin();
      }
      setIsDelistLoading(true);
      console.log("_________DeListing Item for Sale start_______");
      // Address for Nft  Contract - read params from url.
      const nftContractAddressObject = new Address(nft.sellIntentAddress);
      console.log(collectionId);
      const nftInstance = new venomProvider.Contract(
        DIRECTSELLABI,
        nftContractAddressObject
      );
      // Address of connected wallet to the applciation
      const connectedUser = new Address(address);
      // Create Instance of FactoryDirectSell.sol
      const factoryDSInstance = new venomProvider.Contract(
        FactoryDSABI,
        new Address(environment.factoryDirectSell)
      );
      // get gas value for listing
      const gasValue = (await factoryDSInstance.methods.getGasValue().call())
        .value0;

      const DelistTx = await nftInstance.methods
        .closeSell({ callbackId: 0 })
        .send({
          from: connectedUser,
          amount: (
            calcValue(gasValue.cancel, gasValue.gasK) + 200000000
          ).toString(),
        });

      if (DelistTx) {
        const status = await callTransactionStatus(
          DelistTx?.outMessages[0].hash
        );
        if (status) {
          toast.success("Delisted Successfully");
          setIsDelistLoading(false);
          dispatch(
            getOwnerAndManager({
              collectionAddress: nft?.collectionAddress,
              NftAddress: nft?.nftContractAddress,
            })
          );
        } else {
          toast.error("Something went wrong try again");
          setIsDelistLoading(false);
        }
      }

      //
    } catch (error) {
      setIsDelistLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleBuy = async () => {
    try {
      if (!address) {
        return onLogin();
      }
      setIsBuyLoading(true);
      console.log("_______buying nft ________");
      // get wever vault address.
      const wEverVaultAddress = new Address(environment.vaultAddress); // TODO: remove hardcode read from config
      const wEverVaultInstance = new venomProvider.Contract(
        VaultABI,
        wEverVaultAddress
      );
      // get gas values
      const factoryDSInstance = new venomProvider.Contract(
        FactoryDSABI,
        new Address(environment.factoryDirectSell)
      );
      // get gas value for listing
      const gasValue = (await factoryDSInstance.methods.getGasValue().call())
        .value0;
      const _buyGas = calcValue(gasValue.buy, gasValue.gasK);
      console.log(_buyGas);
      const transferValue = (
        _buyGas +
        300000000 +
        Number(nft.listPrice) * 1000000000
      ).toString();

      const connectedUser = new Address(address);
      // call Method wrap on the instance.
      // owner_address should be the sellIntent smart contract deployed wihen listing // manager from wallet
      const wrapTx = await wEverVaultInstance.methods
        .wrap({
          tokens: (Number(nft.listPrice) * 1000000000).toString(),
          owner_address: new Address(nft.sellIntentAddress),
          gas_back_address: connectedUser,
          payload: "",
        })
        .send({
          from: connectedUser,
          amount: transferValue,
        });
      console.log(wrapTx);
      //  handle redux state for when transacction is successfully

      const status = await callTransactionStatus(wrapTx.outMessages[0].hash);
      if (status) {
        dispatch(
          getOwnerAndManager({
            collectionAddress: nft?.collectionAddress,
            NftAddress: nft?.nftContractAddress,
          })
        );
        setIsBuyLoading(false);
        toast.success("Transactions successfully");
      } else {
        setIsBuyLoading(false);
        toast.error("Transactions failed try again");
      }
    } catch (error) {
      setIsBuyLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  useEffect(() => {
    dispatch(
      getOwnerAndManager({
        collectionAddress: nft?.collectionAddress,
        NftAddress: nft?.nftContractAddress,
      })
    );
  }, [nft?.collectionAddress, nft?.nftContractAddress]);
  console.log(nft_Owner_Manager, "nft_Owner_Manager");
  return (
    <>
      <ListingModal
        open={open}
        handleClose={() => setOpen(false)}
        handleList={handleList}
        nft={nft}
        isListLoading={isListLoading}
      />
      <div className="right-detail flex flex-col gap-[0.8em]">
        <div className="flex gap-2">
          <img src={NftDetailIcon} alt="pr" className="w-6 h-6" />
          <div
            className="font-bold text-lg cursor-pointer flex items-center gap-2 text-[#1d4ed8] hover:text-[#1d4ed8ab]"
            onClick={() => navigate(`/collections/${nft.collectionAddress}`)}
          >
            <span>{nft.collectionName}</span>
            {nft?.collection?.isverified && (
              <img
                src={VerifiedIcon}
                className="w-[20px] h-[20px]"
                alt="Verified Icon"
              />
            )}
          </div>
        </div>
        <div className="font-extrabold text-2xl ">
          {nft.collectionName} #{nft.nftId}
        </div>
        {isOwnerFetchLoading ? (
          <div className="animate-pulse flex h-7 bg-[#e2e2e2] w-1/6 rounded-md"></div>
        ) : (
          <div className="text-sm">
            Owned by{" "}
            <span
              className="text-[#1d4ed8] hover:text-[#1d4ed8ab] cursor-pointer"
              onClick={() => navigate(`/${nft.ownerAddress}`)}
            >
              {formatAddress(nft_Owner_Manager?.ownerAddress)}
            </span>
          </div>
        )}

        <div className="text-sm">
          Contract:{" "}
          <span
            className="text-[#1d4ed8] hover:text-[#1d4ed8ab] cursor-pointer"
            onClick={() => {
              if (nft && nft.collectionAddress) {
                window.open(
                  `https://venomscan.com/accounts/${nft.collectionAddress}`,
                  "_blank"
                );
              }
            }}
          >
            {formatAddress(nft?.collectionAddress)}
          </span>
        </div>
        <div className=" w-full h-[229px] border border-1-[rgba(0, 0, 0, 0.81)] rounded-[8px]">
          {/** Connected User owns NFT and its already listed---Show user option to delist**/}
          {address === nft_Owner_Manager?.ownerAddress &&
            nft_Owner_Manager.sellIntentAddress && (
              <>
                <div className=" p-[1em] border-2-red h-[fill-available]">
                  <div className="text-[#707A83] font-bold cursor-pointer">
                    Current Price
                  </div>
                  <div className="flex gap-2 items-center">
                    <span className="text-2xl font-extrabold cursor-pointer">
                      {nft_Owner_Manager.listPrice} VENOM
                    </span>
                  </div>
                </div>
                <div className="flex gap-3 ">
                  <button
                    className=" cursor-pointer flex items-center justify-center gap-2 bg-[#1D6C64] w-[283px] h-[56px] rounded-[90px]"
                    onClick={handleDeList}
                    disabled={isDeListLoading}
                  >
                    <span className="text-white font-bold">
                      {isDeListLoading ? "loading..." : "Delist"}
                    </span>{" "}
                  </button>
                  <button
                    disabled
                    className="cursor-not-allowed flex items-center justify-center gap-2 bg-[#F4F4F4] w-[283px] h-[56px] rounded-[90px] "
                  >
                    <span className="font-bold ">Accept Offer</span>
                  </button>
                </div>
              </>
            )}
          {/** Connected User owns NFT and its not listed---Show user option to list**/}
          {address === nft_Owner_Manager?.ownerAddress &&
            !nft_Owner_Manager.sellIntentAddress && (
              <div className="flex gap-3 mt-[3em]">
                <button
                  className=" cursor-pointer flex items-center justify-center gap-2 bg-[#1D6C64] w-[283px] h-[56px] rounded-[90px]"
                  onClick={() => setOpen(true)} //openmodal for list
                  disabled={isListLoading}
                >
                  <div className="text-white font-bold">
                    {isListLoading ? "Loading..." : "List"}
                  </div>
                </button>
              </div>
            )}
          {/** Connected User does not own NFT and its already listed---Show user option to Buy**/}
          {address != nft_Owner_Manager?.ownerAddress &&
            nft_Owner_Manager.sellIntentAddress && (
              <>
                <div className=" p-[1em] border-2-red h-[fill-available]">
                  <div className="text-[#707A83] font-bold">Current Price</div>
                  <div className="flex gap-2 items-center">
                    <span className="text-2xl font-extrabold">
                      {nft.listPrice} VENOM
                    </span>
                  </div>
                </div>
                <div className="flex gap-3 p-[1em] ">
                  <button
                    className=" cursor-pointer flex items-center justify-center gap-2 bg-[#1D6C64] w-[283px] h-[56px] rounded-[90px]"
                    onClick={handleBuy}
                    disabled={isBuyLoading}
                  >
                    <div className="text-white font-bold">
                      {isBuyLoading ? "Loading..." : "Buy"}
                    </div>{" "}
                    <img src={ShoppingCartIcon} alt="shopping" />
                  </button>
                  <div
                    disabled
                    className="cursor-not-allowed flex items-center justify-center gap-2 bg-[#F4F4F4] w-[283px] h-[56px] rounded-[90px]"
                  >
                    <div className="font-bold ">
                      Make Offer
                    </div>
                  </div>
                </div>
              </>
            )}
          {/** Connected User does not own NFT and its not listed---Show user its not listed but can make offer**/}
          {address !== nft_Owner_Manager?.ownerAddress &&
            !nft_Owner_Manager.sellIntentAddress && (
              <div className="flex mt-[3em] items-center gap-3 p-[1em] border-2-red h-[fill-available]">
                <div className="text-[#707A83] font-bold">Not Listed</div>
                <div className="relative">
                  <div className="cursor-not-allowed flex items-center justify-center gap-2 bg-[#F4F4F4] w-[283px] h-[56px] rounded-[90px] group">
                    <button className="font-bold cursor-not-allowed" disabled>
                      Make Offer
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
        <Collapse
          // accordion
          items={priceHistory}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (!isActive ? <MenuOutlined /> : "")}
          className="flex flex-col  w-full h-[fit] mt-[10px]"
        />
      </div>
    </>
  );
}

export default NFTActionArea;
