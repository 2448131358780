import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/service";

// Create async thunks
export const getDrops = createAsyncThunk(
  "/drops",
  async (_, { rejectWithValue }) => {
    try {
      const res = await API.get(`/drops`);

      if (res.data.success) {
        return res.data.result;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error in walletConnection");
    }
  }
);

// Create async thunks
export const getDropById = createAsyncThunk(
  "/drops/getDropById",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.get(`/drops/${payload.dropId}`);

      if (res.data.success) {
        return res.data.dropDoc;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error in walletConnection");
    }
  }
);

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

let initialState = {
  drops: [],
  drop: {},
  loader: false,
  success: false,
};

export const dropSlice = createSlice({
  name: "drops",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDrops.fulfilled, (state, { payload }) => {
        console.log("drops", payload);
        state.drops = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getDrops.pending, (state) => {
        console.log("ispending drops");
        state.loader = true;
        state.success = false;
      })
      .addCase(getDrops.rejected, (state, { payload }) => {
        console.log("is rejected drops", payload);
        state.loader = false;
        state.success = false;
      })
      .addCase(getDropById.fulfilled, (state, { payload }) => {
        state.drop = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getDropById.pending, (state) => {
        console.log("ispending drop");
        state.loader = true;
        state.success = false;
      })
      .addCase(getDropById.rejected, (state, { payload }) => {
        console.log("is rejected drop", payload);
        state.loader = false;
        state.success = false;
      });
  },
});

export const {} = dropSlice.actions;

export default dropSlice.reducer;
