import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopCollections } from "../redux/reducers/collection";
import Card from "../common/card";
import SkeletonCard from "../common/SkeletonCard";

function TopCollection({ categoryName }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTopCollections(categoryName));
  }, [categoryName]);
  const {
    loader,
    featuredCollections,
    topCollectionLoader,
    topCollectionSuccess,
    topCollections,
  } = useSelector((state) => state.collection);

  if (topCollectionLoader) {
    return (
      <div className="px-4  md:px-18 mt-3">
        <div className="flex justify-between ">
          <p className=" text-[2em] font-extrabold text-black ">
            Top Collections
          </p>
          <p className="font-bold text-base bg-gradient-to-r text-transparent bg-clip-text from-[#73E0A9] to-[#5B68DF]">
            View All
          </p>
        </div>

        <div className=" flex flex-wrap gap-3 justify-center sm:justify-start mt-[0.5em] pb-10">
          {Array(10)
            .fill(true)
            .map((item, index) => (
              <SkeletonCard key={index} />
            ))}
        </div>
      </div>
    );
  }

  if (topCollectionSuccess) {
    return (
      <div className="px-4  md:px-18 lg:px-18  mt-3 ">
        <div className="relative flex h-16 items-center justify-between ">
          <p className=" text-[1.5em] md:text-[2em] font-extrabold text-black ">
            Top Collections
          </p>
          <p className=" font-bold text-base bg-gradient-to-r text-transparent bg-clip-text from-[#73E0A9] to-[#5B68DF]">
            View All
          </p>
        </div>

        <div className=" max-w-[1500px] grid grid-cols-2 mx-auto lg:grid-cols-4 md:grid-cols-3 gap-[2px]" >
          {topCollections?.map((collection, index) => {
            return <>
            <Card collection={collection} key={index} />
            </> 
          })}
        </div>
      </div>
    );
  }
}

export default TopCollection;
