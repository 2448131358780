import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/service";


export const privacypolicySlice = createSlice({
  name: "policy",
  initialState: {
    popup: false
  },
  reducers: {
    setModel: (state, { payload }) => {
      state.popup = payload;
    }
  },
});

export const { setModel } = privacypolicySlice.actions;
export default privacypolicySlice.reducer;
