import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "antd";
import { MenuOutlined, CaretDownOutlined } from "@ant-design/icons";

import DefaultCover from "../../utils/svgs/defaulCover.png";
import ImageChecker from "../../sharedComponents/Image";

import NFTTraits from "./NFTTraits";

import NFTDetails from "./NFTDetails";


const genExtra = () => (
  <CaretDownOutlined
    onClick={(event) => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    }}
  />
);

function NFTImage(props) {
  const { nft, moreFromThisCollection, nftActivity } = useSelector(
    (state) => state.collection
  );
 
  const items = [
    {
      key: "1",
      label: <p className="font-bold text-md cursor-pointer">Description</p>,
      children: <p>{nft.description}</p>,
      extra: genExtra(),
    },
    {
      key: "2",
      label: <p className="font-bold text-md">Traits</p>,
      children: <NFTTraits/>,
      extra: genExtra(),
    },
    {
      key: "3",
      label: <p className="font-bold text-md">Details</p>,
      children: <NFTDetails nft={nft}/>,
      extra: genExtra(),
    },
  ];
  return (
    <div className="flex flex-col gap-[0.5em]">
      <div className="flex flex-col  w-full h-[400px] border border-2-[rgba(138, 138, 138, 1)] rounded-[8px]">
        <ImageChecker
          imageUrl={nft.nftImage}
          altImage={DefaultCover}
          styles={
            "py-[2em] mx-0 my-auto h-[400px] w-full object-contain object-top"
          }
          divStyle={"w-full "}
        />
      </div>
      <Collapse
        // accordion
        items={items}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (!isActive ? <MenuOutlined /> : "")}
        className="flex flex-col   w-ful h-[fit]"
      />
    </div>
  );
}

export default NFTImage;
