import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getListings } from '../../redux/reducers/collection';

const NFTListings = () => {
    const dispatch = useDispatch();
  const { nftListing } = useSelector((state) => state.collection);
  const { collectionId, nftAddress } = useParams();

  useEffect(() => {
    dispatch(
        getListings({
        collectionAddress: collectionId,
        nftAddress: nftAddress,
      })
    );
  }, [collectionId, nftAddress]);
  return (
    <div className='w-[100%]'>
    <table className='min-w-full divide-y divide-gray-200'>
      <thead className='bg-gray-50'>
        <tr>
         
          <th
            scope='col'
            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
          >
            Price
          </th>
          <th
            scope='col'
            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
          >
            From
          </th>
          <th
            scope='col'
            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
          >
            Date
          </th>
        </tr>
      </thead>
      <tbody className='bg-white divide-y divide-gray-200'>
        {nftListing.map((item) => (
          <tr key={item._id}>
            <td className='px-6 py-4 whitespace-nowrap  font-bold'>
              {item.price}
            </td>
            <td className='px-6 py-4 whitespace-nowrap text-[#2081E2] font-bold'>
              {/* {trimString(item?.from.toString())} */}
              <Link to={`/${item._from}`}>{item.from}</Link>
            </td>
           
            <td className='px-6 py-4 whitespace-nowrap  font-bold'>
              {item.date}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default NFTListings
