import React from "react";
import { formatAddress } from "../../utils/formatAddress";

const NFTDetails = ({ nft }) => {
  console.log(nft,"@#SDSF")
  return (
    <div>
      <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Contract Address
        </dt>
        <dd className="mt-1 text-sm leading-6 text-end text-gray-700 sm:col-span-2 sm:mt-0">
        
          <span
            className="text-[#1d4ed8] hover:text-[#1d4ed8ab] cursor-pointer"
            onClick={() => {
              if (nft && nft.collectionAddress) {
                window.open(
                  `https://venomscan.com/accounts/${nft.collectionAddress}`,
                  "_blank"
                );
              }
            }}
          >
             {formatAddress(nft?.collectionAddress)}
          </span>


        </dd>
      </div>
      <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
        Nft Contract Address
        </dt>
        <dd className="mt-1 text-sm leading-6 text-end text-gray-700 sm:col-span-2 sm:mt-0">
        
          <span
            className="text-[#1d4ed8] hover:text-[#1d4ed8ab] cursor-pointer"
            onClick={() => {
              if (nft && nft.nftContractAddress) {
                window.open(
                  `https://venomscan.com/accounts/${nft.nftContractAddress}`,
                  "_blank"
                );
              }
            }}
          >
             {formatAddress(nft?.nftContractAddress)}
          </span>


        </dd>
      </div>
      <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Token ID
        </dt>
        <dd className="mt-1 text-sm leading-6 text-end text-[#1d4ed8] sm:col-span-2 sm:mt-0">
          {nft.nftId ? nft.nftId : ""}
        </dd>
      </div>
      <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Token Standard
        </dt>
        <dd className="mt-1 text-sm leading-6 text-end text-[#1d4ed8] sm:col-span-2 sm:mt-0">
          TIP4
        </dd>
      </div>
      <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">Chain</dt>
        <dd className="mt-1 text-sm leading-6 text-end text-[#1d4ed8] sm:col-span-2 sm:mt-0">
          Venom
        </dd>
      </div>
      <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Creator Earnings
        </dt>
        <dd className="mt-1 text-sm leading-6 text-end text-[#1d4ed8] sm:col-span-2 sm:mt-0">
          {nft?.collection?.royality ? nft?.collection?.royality : "0.00"}%
        </dd>
      </div>
    </div>
  );
};

export default NFTDetails;
