import React from "react";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import Loading from "../../common/loading";

const SearchResults = ({ results, handleReset, searchLoading, searchText }) => {
  console.log(results, "searchresult");
  const navigate = useNavigate();
  return (
    <>
      {Object.keys(results).length > 0 && (
        <>
          <div className="search-result-body absolute h-[auto] max-h-[50vh] max rounded-b-[10px] rounded-r-[10px] shadow-xl  w-[45vw] bg-[white] rounded mt-2 pb-3 lg:w-[45vw] md:w-[70vw] sm:w-[70vw]  overflow-x-hidden">
            {results?.collections?.length > 0 && (
              <>
                <h1 className="font-bold pl-2 pt-2">Collections</h1>
                {results?.collections.map((collection, index) => (
                  <>
                    <div className="flex-col items-center gap-2 " key={index}>
                      {/* <img src='/images/play.png' alt='img'/> */}
                      <div
                        className="flex gap-2 items-center hover:bg-gray-200 hover:w-full cursor-pointer p-1 pl-2"
                        onClick={() => {
                          // onClickListItem(
                          //   collection.collectionAddress ||
                          //     collection.walletAddress ||
                          //     collection.nftContractAddress
                          // )
                          navigate(
                            `collections/${collection?.collectionAddress}`
                          );
                          handleReset();
                        }}
                      >
                        {/* <div className="w-7 h-7 bg-green-500 rounded-full hover "></div> */}
                        <Avatar src={collection?.collectionImage} />
                        <span>
                          {collection.collectionName ||
                            collection.walletAddress ||
                            collection.nftContractAddress}
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
            {results?.users?.length > 0 && (
              <>
                <h1 className="font-bold pl-2 pt-2">Users</h1>
                {results?.users.map((user, index) => (
                  <>
                    <div className="flex-col items-center gap-2 " key={index}>
                      {/* <img src='/images/play.png' alt='img'/> */}
                      <div
                        className="flex gap-2 items-center hover:bg-gray-200 hover:w-full cursor-pointer p-1 pl-2"
                        onClick={() => {
                          navigate(`${user?.walletAddress}`);
                          handleReset();
                        }}
                      >
                        <Avatar src={user?.profilePicture} />
                        <span>{user.userName || user.walletAddress}</span>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}

            {results?.nfts?.length > 0 && (
              <>
                <h1 className="font-bold pl-2 pt-2">NFTs</h1>
                {results?.nfts.map((nft, index) => (
                  <>
                    <div className="flex-col items-center gap-2 " key={index}>
                      {/* <img src='/images/play.png' alt='img'/> */}
                      <div
                        className="flex gap-2 items-center hover:bg-gray-200 hover:w-full cursor-pointer p-1 pl-2"
                        onClick={() => {
                          navigate(
                            `collections/${nft?.collectionAddress}/${nft?.nftContractAddress}`
                          );
                          handleReset();
                        }}
                      >
                        <Avatar src={nft?.nftImage} />
                        <span>
                          {nft.collectionName || nft.nftContractAddress}
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </>
      )}
      {searchLoading && (
        <div className="search-result-body absolute h-[auto] max-h-[50vh] max rounded-b-[10px] rounded-r-[10px] shadow-xl  w-[45vw] bg-[white] rounded mt-2 pb-3 lg:w-[45vw] md:w-[70vw] sm:w-[70vw]  overflow-x-hidden">
          <div className="flex w-full h-[10vh] items-center justify-center">
            <Loading />
          </div>
        </div>
      )}
      {searchText?.length > 3 &&
        !searchLoading &&
        Object.keys(results).length === 0 && (
          <div className="p-[18px] font-bold text-start text-gray-600 search-result-body absolute h-[auto] max-h-[50vh] max rounded-b-[10px] rounded-r-[10px] shadow-xl  w-[45vw] bg-[white] rounded mt-2 pb-3 lg:w-[45vw] md:w-[70vw] sm:w-[70vw]  overflow-x-hidden">
            No Items Found
          </div>
        )}
    </>
  );
};

export default SearchResults;
