import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Collapse, Popover } from "antd";
import {
  MenuOutlined,
  CaretDownOutlined,
  TagOutlined,
  ShoppingCartOutlined,
  SketchOutlined,
} from "@ant-design/icons";
import { timeAgo, formatTime } from "../../utils/defaultFunctions";
import { getNftActivity } from "../../redux/reducers/collection";

const DataTable = ({ data }) => {
  const renderIcons = {
    NftCreated: <TagOutlined />,
    OwnerChanged: <ShoppingCartOutlined />,
    SALE: <SketchOutlined />,
    LIST: <ShoppingCartOutlined />,
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Event
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Price
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              From
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              To
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data?.map((item) => (
            <tr key={item._id}>
              <td className="px-6 py-4 whitespace-nowrap font-bold">
                <div className="flex items-center gap-2">
                  <span>{renderIcons[item.event]}</span>
                  <span>{item.event}</span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap font-bold">
                {item.price ? item.price : "-"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[#2081E2] font-bold">
                {item._from !==
                "0x0000000000000000000000000000000000000000000000000000000000000000" ? (
                  <a
                    href={`https://venomscan.com/accounts/${item._from}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.from}
                  </a>
                ) : (
                  <span>{item.from}</span>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[#2081E2] font-bold">
                <a
                  href={`https://venomscan.com/accounts/${item._to}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.to}
                </a>
              </td>
              <td className="px-6 py-4 whitespace-nowrap font-bold">
                <Popover content={formatTime(item.date)}>
                  {timeAgo(item.date)}
                </Popover>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const genExtra = () => (
  <CaretDownOutlined
    onClick={(event) => {
      event.stopPropagation();
    }}
  />
);

const NFTActivity = () => {
  const dispatch = useDispatch();
  const { nftActivity, nftActivityPagination } = useSelector(
    (state) => state.collection
  );
  const { collectionId, nftAddress } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [nftPagination, setNftPagination] = useState({
    page: 1,
    limit: 5,
  });

  const handleNftActivityPage = () => {
    setNftPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getNftActivity({
        collectionAddress: collectionId,
        nftAddress: nftAddress,
        page: nftPagination.page,
        limit: nftPagination.limit,
      })
    ).then(() => {
      setIsLoading(false);
    });
  }, [collectionId, nftAddress, nftPagination]);



  return (
    <div className="mt-4">
      <Collapse
        accordion
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (!isActive ? <MenuOutlined /> : null)}
      >
        <Collapse.Panel
          key="1"
          header={<p className="font-bold text-lg">Item Activity</p>}
          extra={genExtra()}
        >
          <DataTable data={nftActivity} />
          {
            (nftPagination?.page != nftActivityPagination?.totalPages && (
              <div className="flex justify-center mt-4">
                <button
                  className="px-4 py-2 text-sm font-medium text-gray-800 bg-gray-200 rounded hover:bg-gray-300"
                  onClick={handleNftActivityPage}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Load More"}
                </button>
              </div>
            ))
          }
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default NFTActivity;
