import React from 'react';
import DummayCollectionImage from '../utils/svgs/dummyCollection.png';
import ImageChecker from '../sharedComponents/Image';
//This res-usable component is for CollectionCard.
const ExploreCard = ({ categoryName, categoryImage }) => {
  return (
    <div className='cursor-pointer w-[288px] h-[240px] border border-1 rounded-[13px] drop-shadow transition-transform transform-gpu hover:translate-y-[-5px]'>
      <div className='w-full h-[150]'>
        <ImageChecker
          imageUrl={categoryImage}
          altImage={DummayCollectionImage}
          styles={
            'w-[288px] h-[192px] transition-transform transform object-cover hover:scale-105'
          }
        />
      </div>
      <div className='flex flex-col justify-between w-[253px] h-[8px] m-[1em] '>
        <span className='text-md font-extrabold'>{categoryName}</span>
      </div>
    </div>
  );
};

export default ExploreCard;
