import { Outlet } from 'react-router-dom';

import Header from './layout/header';
import Footer from './layout/footer';
import { Toaster } from 'react-hot-toast';

import './App.css';
import { useEffect } from 'react';
import { getAllCategories } from './redux/reducers/categories';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import PrivacypolicyPopup from './common/PrivacypolicyPopup';


export default function App() {
  const dispatch = useDispatch();
  // const location = useLocation();
  // useEffect(() => {
  //   ReactGA.initialize("G-X0S501JDC1");
  //   ReactGA.pageview(location.pathname + location.search);
  // }, []);
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  return (
    <div className="body">
      <Helmet>
        <meta
          property="og:image"
          content="https://venomsea.s3.amazonaws.com/testnet-sample-collections/venompunks/dropImgLink.png"
        />
      </Helmet>
      
      <Toaster />
      <Header />
      {/* <div id='detail' className='bg-[#042134]'> */}
      <div id="detail" className="bg-[#fff]">
        <Outlet />
      </div>
      <PrivacypolicyPopup/>
      <Footer />
    </div>
  );
}
