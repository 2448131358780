import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { getAllCollections } from "../redux/reducers/collection";
// import CollectionDetailPage from "./CollectionDetailPage";
import Featured from "./FeaturedSection";
import TopCollection from "./TopCollection";
// import Ranking from "./Ranking";
// import ExploreCard from "../common/exploreCard";
import ExoloreCategories from "./ExploreCategories";
import PrivacypolicyPopup from "../common/PrivacypolicyPopup";
import Stats from "./Stats";

function HomePage(props) {
  const [activeTab, setActiveTab] = useState("");
  const categories = ["", "Art", "Gaming", "PFPs"];
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getAllCollections());
  // }, []);

  return (
    <div className="mt-20">
      <div className="min-h-[53vh]">
        <Featured
          categories={categories}
          categoryName={activeTab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <Stats categoryName={activeTab} />
      <TopCollection categoryName={activeTab} />
      <ExoloreCategories />
      <PrivacypolicyPopup />
    </div>
  );
}

export default HomePage;
