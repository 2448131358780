import React, { useState } from "react";
import defaulCover from "../utils/svgs/defaulCover.png";
import ImageChecker from "../sharedComponents/Image";
import VerifiedIcon from "../utils/svgs/verifiedIcon.png";
import venomIcon from "../utils/svgs/v.svg";
import { Tooltip } from "antd";

const NftCard = ({ nft, handleClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="group relative cursor-pointer bg-white border border-gray-200 rounded-lg shadow"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <>
          <div className="cursor-pointer absolute right-4 top-4  z-[1] ">
            <Tooltip title="Venom Chain" placement="leftBottom" color="gray" >
           <div className="p-2 bg-[#ffffffb3]  rounded-full">
           <img
                src={venomIcon}
                className="w-[1em] h-[1em]"
                alt="Verified"
              />
           </div>
            </Tooltip>
          </div>
        </>
      )}

      <div className="overflow-hidden shadow-2xl rounded-[13px] bg-gray-200 lg:aspect-none group-hover:opacity-95 lg:h-auto">
        <ImageChecker
          imageUrl={nft?.nftImage}
          altImage={defaulCover}
          styles="h-full w-full object-cover object-center lg:h-full lg:w-full"
        />
      </div>
      <div className="flex flex-col justify-between m-[0.5em] ">
        <div className="flex justify-between">
          <span className="text-lg font-bold text-gray-700 ">
            {nft.collectionName}
          </span>
          <span className="font-bold">
            {nft.rankInCollection ? `#${Math.floor(nft.rankInCollection)}` : ""}
          </span>
        </div>
        <div>
          {nft.listPrice && (
            <div className="font-bold flex items-center gap-1">
              {nft.listPrice.toString()}
              <img src={venomIcon} className="w-[12px] h-[12px] mb-1" alt="V" />
            </div>
          )}
          {nft.lastSale && (
            <div className="text-[#707A83] text-sm font-bold ">
              Last Sale: {nft.lastSale}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NftCard;
