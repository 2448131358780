import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/service";
import { trimString } from "../../utils/defaultFunctions";
import { filter } from "lodash";

// Create async thunks
export const getAllCollections = createAsyncThunk(
  "collections/getAllCollections",
  async (_, { rejectWithValue }) => {
    try {
      const res = await API.get(`/collections`);

      if (res.data.result) {
        return res.data.result;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getTopCollections = createAsyncThunk(
  "collections/topCollections",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.get(`/topCollections?categoryName=${payload}`);
      if (res.data.success === true) {
        return res.data.featuredCollectionsList;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getFeaturedCollection = createAsyncThunk(
  "collections/featuredCollection",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.get(`/featured?categoryName=${payload}`);
      if (res.data.success === true) {
        return res.data.featuredCollectionsList;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getCollectionById = createAsyncThunk(
  "collections/getCollectionById",
  async (collectionAddress, { rejectWithValue }) => {
    try {
      const res = await API.get(`/collections/${collectionAddress}`);
      if (res.data.success) {
        return res.data.collection;
      } else {
        return {};
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getCollectionDetails = createAsyncThunk(
  "collections/getCollectionDetails",
  async ({ collectionId, page, pageSize, filter }, { rejectWithValue }) => {
    try {
      const res = await API.post(`/collections/${collectionId}/nfts`, {
        page,
        pageSize,
        filter,
      });
      if (res.data.success) {
        return { nfts: res.data.nfts, totalNfts: res.data.totalCount };
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections");
    }
  }
);
export const getNftById = createAsyncThunk(
  "collections/getNftById",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(resetNFtDetail());
      const res = await API.get(
        `/collections/${payload.collectionAddress}/nfts/${payload.nftAddress}`
      );
      if (res.data.success) {
        return res.data.nft;
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getMoreFromThisCollection = createAsyncThunk(
  "collections/getMoreFromThisCollection",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.get(
        `/collections/${payload.collectionAddress}/nfts/${payload.nftAddress}/moreFromThisCollection`
      );

      if (res.data.success) {
        return res.data.nfts;
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getNftActivity = createAsyncThunk(
  "collections/getNftActivity",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { collectionAddress, nftAddress, page, limit } = payload;
      const res = await API.get(
        `/collections/${collectionAddress}/nfts/${nftAddress}/itemActivity`,
        {
          params: {
            page,
            limit,
          },
        }
      );
      if (res.data.success) {
        return {
          nftActivity: res.data.nftActivity,
          activityPagination: res.data.pagination,
        };
     
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getCollectionNftActivites = createAsyncThunk(
  "collections/getCollectionNftActivites",
  async ({ collectionId, page, limit }, { rejectWithValue }) => {
    try {
      const res = await API.get(`/collections/${collectionId}/nftsactivities`, {
        params: { page, limit },
      });

      if (res.data.success) {
        return {
          collectionActivity: res.data.collectionActivity,
          totalActivityPages: res.data.totalPages,
        };
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections Activity");
    }
  }
);

export const getListings = createAsyncThunk(
  "collections/getListings",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.get(
        `/collections/${payload.collectionAddress}/nfts/${payload.nftAddress}/listings`
      );
      if (res.data.success) {
        return res.data.nftActivity;
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getNftPriceHistory = createAsyncThunk(
  "collections/getNftPriceHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.get(
        `/collections/nfts/${payload.nftAddress}/nftPriceHistory`
      );
      if (res.data.success) {
        return res.data;
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching collections");
    }
  }
);

export const getNftAttributes = createAsyncThunk(
  "collections/getNftAttributes",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.get(
        `/collections/${payload.collectionAddress}/nfts/${payload.nftAddress}/nftattributes`
      );
      if (res.data.success) {
        return res.data;
      } else {
        return {};
      }
    } catch (err) {
      return rejectWithValue("Error fetching nft attributes");
    }
  }
);

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

let initialState = {
  collections: [],
  nfts: [],
  featuredCollections: [{}],
  featuredloading: false,
  featuredError: false,
  featuredSuccess: false,
  ERROR: "error",
  LOADING: "loading",
  topCollections: [],
  collectpending: [],
  collectionDetail: {},
  nft: {},
  totalNfts: 0,
  nftActivity: [],
  nftActivitLoading: false,
  nftActivityPagination: {},

  nftListing: [],
  priceHistory: [],
  collectionNftActivites: [],
  activityTotalPages: 0,
  collectionNftActivitesLoading: false,
  moreFromThisCollection: [],
  nftAttributes: {},

  nftAttributesLoading: false,
  nftloader: false,
  collectionNftLoading: false,
  chartloader: false,
  collectionloader: false,
  success: false,
  topCollectionLoader: false,
  topCollectionSuccess: false,
};

export const collectionSlice = createSlice({
  name: "collectionSlice",
  initialState,
  reducers: {
    resetCollectionDetail: (state) => {
      state.collectionDetail = {};
    },
    resetNFtDetail: (state) => {
      state.nft = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCollections.fulfilled, (state, { payload }) => {
        console.log(payload, "action payload");
        state.collections = payload;
      })
      .addCase(getTopCollections.fulfilled, (state, { payload }) => {
        console.log(payload, "action payload");
        state.topCollections = payload;
        state.topCollectionSuccess = true;
        state.topCollectionLoader = false;
      })

      .addCase(getTopCollections.pending, (state) => {
        state.topCollectionLoader = true;
        state.topCollectionSuccess = false;
      })

      .addCase(getFeaturedCollection.fulfilled, (state, { payload }) => {
        console.log(payload, "action payload featured");
        state.featuredCollections = payload;
        state.featuredloading = false;
        state.featuredError = false;
        state.featuredSuccess = true;
      })
      .addCase(getCollectionById.fulfilled, (state, { payload }) => {
        console.log(payload, "action payload byid");
        state.collectionDetail = payload;
        state.collectionloader = false;
        state.success = true;
      })
      .addCase(getNftById.fulfilled, (state, { payload }) => {
        console.log(payload, "payload nft by id");
        state.nft = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getMoreFromThisCollection.fulfilled, (state, { payload }) => {
        state.moreFromThisCollection = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getCollectionDetails.fulfilled, (state, { payload }) => {
        const newData = payload.nfts.filter(
          (newNft) =>
            !state.nfts.some((existingNft) => existingNft._id === newNft._id)
        );
        state.nfts = [...state.nfts, ...newData];
        state.totalNfts = payload.totalNfts;
        state.nftloader = false;
        state.success = true;
      })
      .addCase(getNftActivity.fulfilled, (state, { payload }) => {

        const newActivities = payload?.nftActivity?.filter(
          (activity) =>
            !state.nftActivity.some(
              (existingActivity) => existingActivity._id === activity._id
            )
        );

        const newPay = newActivities.map((el) => {
          return {
            ...el,
            from: trimString(el.from),
            _from: el.from,
            to: trimString(el.to),
            _to: el.to,
          };
        });
        state.nftActivity = [
          ...state.nftActivity,
          ...newPay,
        ];
        // state.nftActivity = newPay;
        state.nftActivityPagination = payload.activityPagination;
      })
      .addCase(getCollectionNftActivites.fulfilled, (state, { payload }) => {
        const newActivities = payload?.collectionActivity?.filter(
          (activity) =>
            !state.collectionNftActivites.some(
              (existingActivity) => existingActivity._id === activity._id
            )
        );

        // Transform and trim the new activities
        const trimmedActivities = newActivities.map((activity) => ({
          ...activity,
          from: trimString(activity.from),
          _from: activity.from,
          to: trimString(activity.to),
          _to: activity.to,
        }));

        // Concatenate the new activities with existing ones
        state.collectionNftActivites = [
          ...state.collectionNftActivites,
          ...trimmedActivities,
        ];
        state.activityTotalPages = payload.totalActivityPages;
        state.collectionNftActivitesLoading = false;
      })

      .addCase(getListings.fulfilled, (state, { payload }) => {
        const newPay = payload.map((el) => {
          return {
            ...el,
            _from: el.from,
            to: trimString(el.to),
            _to: el.to,
          };
        });
        state.nftListing = newPay;
      })

      .addCase(getNftPriceHistory.fulfilled, (state, { payload }) => {
        state.priceHistory = payload;
        state.loader = false;
      })
      .addCase(getNftAttributes.fulfilled, (state, { payload }) => {
        state.nftAttributes = payload;
        state.loader = false;
      })
      .addCase(getNftAttributes.pending, (state) => {
        state.nftAttributesLoading = true;
        state.success = false;
      })
      .addCase(getCollectionDetails.pending, (state) => {
        state.nftloader = true;
        state.success = false;
        state.nfts=[];
      })

      .addCase(getCollectionDetails.rejected, (state) => {
        state.nftloader = false;
        state.success = false;
        state.nfts=[];
      })
      .addCase(getAllCollections.pending, (state) => {
        state.loader = true;
        state.success = false;
      })
      .addCase(getCollectionById.pending, (state) => {
        state.collectionloader = true;
        state.success = false;
      })
      .addCase(getNftById.pending, (state) => {
        state.loader = false;
        state.success = false;
      })
      .addCase(getMoreFromThisCollection.pending, (state) => {})
      .addCase(getFeaturedCollection.pending, (state) => {
        state.featuredloading = true;
        state.featuredError = false;
        state.featuredSuccess = false;
      })
      .addCase(getNftActivity.pending, (state) => {
        console.log("pending activity");
      })
      .addCase(getCollectionNftActivites.pending, (state) => {
        state.collectionNftActivitesLoading = true;
      })
      .addCase(getListings.pending, (state) => {
        console.log("pending listings");
      })
      .addCase(getNftPriceHistory.pending, (state) => {
        console.log("pending activity");
        state.loader = true;
      })
      .addCase(getAllCollections.rejected, (state) => {
        console.log("is rejected");
        state.loader = false;
        state.success = false;
      })
      .addCase(getCollectionById.rejected, (state) => {
        console.log("is rejected");
        state.collectionloader = false;
        state.success = false;
      })
      .addCase(getMoreFromThisCollection.rejected, (state) => {
        console.log("is rejected getMoreFromThisCollection");
      })
      .addCase(getFeaturedCollection.rejected, (state) => {
        state.featuredloading = false;
        state.featuredError = true;
        state.featuredSuccess = false;
        console.log("is rejected getFeaturedCollection");
      })
      .addCase(getNftActivity.rejected, (state) => {
        console.log("rejected activity");
      })
      .addCase(getCollectionNftActivites.rejected, (state) => {
        console.log("rejected activity");
        state.collectionNftActivitesLoading = false;
      })
      .addCase(getListings.rejected, (state) => {
        console.log("rejected listings");
      })
      .addCase(getNftPriceHistory.rejected, (state) => {
        console.log("rejected activity");
      });
  },
});

// Action creators are generated for each case reducer function
export const { resetCollectionDetail, resetNFtDetail } =
  collectionSlice.actions;

export default collectionSlice.reducer;
