import React, { useEffect } from 'react';
import { getAllCollections } from '../redux/reducers/collection';
import { useDispatch, useSelector } from 'react-redux';
import CollectionTable from '../layout/Table';
import './CollectionListPage.css';
import { useNavigate } from 'react-router';

function CollectionListPage(props) {
  const dispatch = useDispatch();
  const { collections } = useSelector((state) => state.collection);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllCollections());
  }, []);

  const handleRowClick = (collectionId) => {
    console.log(`Clicked on collection with ID: ${collectionId}`);
    //TODO: navigate to collection detail view
    navigate(`${collectionId}`);
  };

  return (
    <div className='app-container'>
      <h1>NFT Collections</h1>
      <CollectionTable
        collections={collections ? collections : []}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default CollectionListPage;
