import SvgIcons from "../../utils/svgs/SvgIcons";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserById,
  updateProfile,
  setUpdateLoader,
} from "../../redux/reducers/user";
import { objectToFormData } from "../../utils/defaultFunctions";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const UserProfileUpdate = () => {
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const imageRef1 = useRef(null);
  let { profileId } = useParams();
  const dispatch = useDispatch();
  const { address, user, updateLoader } = useSelector((state) => state.user);
  const { venomInstance, venomProvider, standaloneProvider, walletError } =
    useSelector((state) => state.venom);
  const InitalValue = {
    username: "",
  };
  const [updateUserData, setUpdateUserData] = useState(InitalValue);

  const handleSave = async () => {
    const providerState = await venomProvider?.getProviderState?.();
    const publicKey = providerState?.permissions.accountInteraction?.publicKey;
    dispatch(setUpdateLoader(true));
    try {
      const signString = "Please sign for update your Profile";
      const base64 = btoa(
        JSON.stringify({
          walletAddress: address,
          message: signString,
        })
      );
      const sign = await venomProvider.signDataRaw({
        data: base64,
        publicKey,
      });
      console.log(sign,"asdfic2");
      //dispatch privacy sign wallet here

      let payload = {
        userName: updateUserData.username || user.userName,
        walletAddress: address,
        profilePicture: profilePicture || user.profilePicture,
        backgroundPicture: backgroudPicture || user.backgroudPicture,
        publicKey: publicKey,
        base64: base64,
        signature: sign.signature,
        ShortBio:updateUserData.ShortBio || user.ShortBio
      };

      dispatch(updateProfile(payload));
      console.log("PL", payload);
      //Successful toaster here
      toast.success("Profile Update Successfully!");
      dispatch(setUpdateLoader(false));
      navigate(`/${profileId}`);
      // dispatch(SubmitWallet({ publicKey, base64, signature: sign.signature }));
    } catch (errr) {
      dispatch(setUpdateLoader(false));
      toast.error("Some thing wrong.");
    }
  };

  const handleChange = (val, key) => {
    setUpdateUserData((prev) => ({
      ...prev,
      [key]: val,
    }));
    console.log(val);
  };
  console.log(updateUserData, "asdfasdf234");
  useEffect(() => {
    dispatch(GetUserById(address));
  }, [address]);

  const checkIsValid = () => {
    if (
      updateUserData.username ||
      updateUserData.email ||
      profilePicture ||
      backgroudPicture ||
      updateUserData.ShortBio||
      updateLoader
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleImageClick = () => {
    imageRef.current.click();
  };
  const handleImageClick1 = () => {
    imageRef1.current.click();
  };
  const [profilePicture, setProfilePicture] = useState("");
  const [backgroudPicture, setBackgroudPicture] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imgname = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgname, {
              type: "image/png",
              lastModified: Date.now(),
            });
            console.log(file);
            setProfilePicture(file);
          },
          "image/jpeg",
          0.8
        );
      };
    };
  };

  const handleImageChange1 = (event) => {
    const file = event.target.files[0];
    const imgname = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgname, {
              type: "image/png",
              lastModified: Date.now(),
            });
            console.log(file);
            setBackgroudPicture(file);
          },
          "image/jpeg",
          0.8
        );
      };
    };
  };

  return (
    <div className="mt-[74px]">
      <div className="flex border-b-2 p-4">
        <span className=" text-center	 font-[600] cursor-pointer  bg-[#F4F4F4] px-[10px] py-[10px] rounded">
          Profile
        </span>
        <div className="group relative text-center font-[600] cursor-not-allowed	  px-[10px] py-[10px] ">
          <span>Featured Items</span>
          <div className="bg-zinc-800 p-2 rounded-md group-hover:flex hidden absolute -top-2 -translate-y-full left-1/2 -translate-x-1/2 z-50">
            <span className="text-zinc-400 whitespace-nowrap ">Coming soon</span>
            <div className="bg-inherit rotate-45 p-1 absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 "></div>
          </div>
        </div>
        <div className="group relative text-center font-[600] cursor-not-allowed	  px-[10px] py-[10px] ">
          <span>Notifications</span>
          <div className="bg-zinc-800 p-2 rounded-md group-hover:flex hidden absolute -top-2 -translate-y-full left-1/2 -translate-x-1/2 z-50">
            <span className="text-zinc-400 whitespace-nowrap ">Coming soon</span>
            <div className="bg-inherit rotate-45 p-1 absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2"></div>
          </div>
        </div>
      </div>

      <div>
        <div className="w-full h-[35vh] object-cover relative bg-[#aeaeae]">
          <>
            {backgroudPicture ? (
              <>
                <img
                  className="brightness-75 w-full h-[35vh]  object-cover"
                  src={URL.createObjectURL(backgroudPicture)}
                  alt=""
                />
              </>
            ) : (
              <>
                <img
                  className="brightness-75 w-full h-[35vh]  object-cover"
                  src={user.backgroudPicture}
                  alt=""
                />
              </>
            )}
            <input
              className=" hidden"
              type="file"
              ref={imageRef1}
              onChange={handleImageChange1}
            />
          </>
          <button
            className="float-right absolute bottom-3 right-12 bg-[#292929] border-2 border-[#3e3e3e] rounded-lg text-white px-4 py-2 text-base hover:border-[#fff] cursor-pointer transition"
            onClick={handleImageClick1}
          >
            Upload
          </button>
        </div>

        <div
          className=" bg-[black] w-[168px] h-[168px] mt-[-5em] absolute rounded-full grid items-center justify-items-center "
          onClick={handleImageClick}
        >
          {profilePicture ? (
            <>
              <img
                className="rounded-full h-full w-full "
                src={URL.createObjectURL(profilePicture)}
                alt=""
              />
            </>
          ) : (
            <>
              <img
                className="rounded-full h-full w-full"
                src={user.profilePicture}
                alt=""
              />
            </>
          )}
          <input
            className=" hidden"
            type="file"
            ref={imageRef}
            onChange={handleImageChange}
          />
          <div className=" absolute">
            <SvgIcons.Pen />
          </div>
        </div>
      </div>

      <div className="rounded-lg shadow-md p-6 mt-24">
        <div className="w-full max-w-xl bg-white ">
          <div className="w-full ">
            <h1 className=" font-semibold	text-[30px] ml-4 mt-2">
              Profile Details
            </h1>
            <div className=" mt-6 p-6">
              <div className="">
                <label className="block  text-[16px] font-[600] leading-6 text-gray-900">
                  Full name
                </label>
                <input
                  type="text"
                  name="name"
                  value={updateUserData.username}
                  onChange={(e) => handleChange(e.target.value, "username")}
                  className="w-full bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  placeholder={"Optional"}
                />
              </div>
              <div className="">
                <label className="block text-[16px] font-[600] leading-6 text-gray-900">
                  Email Address
                </label>

                <input
                  type="text"
                  name="email"
                  value={updateUserData.email}
                  onChange={(e) => handleChange(e.target.value, "email")}
                  className="w-full bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  placeholder="Optional"
                />
              </div>
              <div className="">
                <label className="block text-[16px] font-[600] leading-6 text-gray-900 ">
                  Bio
                </label>
                <textarea
                  type="text"
                  name="ShortBio"
                  maxLength={250}
                  onChange={(e) => handleChange(e.target.value, "ShortBio")}
                  // className=" text-[600] h-[125px] "
                  className="w-full min-h-[100px] bg-gray-100 text-gray-900 border-0 rounded-md p-2 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  placeholder="Optional"
                />
                <span className="text-gray-500 text-sm">{updateUserData?.ShortBio?.length}/250 </span>
              </div>

              <div className="">
                <button
                  onClick={handleSave}
                  disabled={!checkIsValid || updateLoader}
                  className={
                    checkIsValid()
                      ? `w-full cursor-pointer my-8 bg-[#1D6C64] hover:bg-[#1d6c64d4] items-center text-center text-white rounded-[14px] px-[25px] py-[15px] font-[600] algin-left`
                      : ` w-full cursor-not-allowed my-8 bg-opacity-[50%] bg-[#1D6C64] items-center text-center text-white rounded-[14px] px-[25px] py-[15px] font-[600] algin-left`
                  }
                >
                  <div className="flex gap-2 justify-center">
                    {updateLoader && <LoadingOutlined />}
                    <span>Save</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default UserProfileUpdate;
