import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utils/service";

// Create async thunks
export const postPendingTransaction = createAsyncThunk(
  "/pendingTrans",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.post(`/pendingtransaction`, {
        event: payload.event,
        hash: payload.hash,
      });

      if (res.data && res.data.success) {
        return res.data.result;
      } else {
        return rejectWithValue("No result found");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err); // pass the actual error object
    }
  }
);

let initialState = {
  pendingTransaction: [],
  loading: false,
  success: false,
};

export const pendingTransactionSlice = createSlice({
  name: "pendingTransaction",
  initialState,
  reducers: {
    resetPendingTransaction: (state, action) => {
      state.pendingTransaction = [];
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPendingTransaction.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.pendingTransaction = [];
      })
      .addCase(postPendingTransaction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.pendingTransaction = payload; // access payload directly
      })
      .addCase(postPendingTransaction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.pendingTransaction = [];
        console.error(action.error); // Log the error for debugging
      });
  },
});

export const { resetPendingTransaction } = pendingTransactionSlice.actions;

export default pendingTransactionSlice.reducer;
